import React, { useEffect } from "react";

function EmptyBag({
  isPickUp,
  product,
  addToCart,
  incrementReturnableQuantity,
  decrementReturnableQuantity,
}) {

  function incrementEmptyBag() {
    if (product.returnableQuantity < product.orderQuantity) {
      incrementReturnableQuantity(product)
    }
  }

  function decrementEmptyBag() {
    if (product.returnableQuantity > 0) {
      decrementReturnableQuantity(product)
    }
  }

  return (
    <div className="emptyBagItemContainer">
      <div className={`empty bag_item ${isPickUp ? "d-none" : ""}`}>
        <div className="product-bag_item">
          <h4 className="emptyBagQuestion text-red">
            ¿Cuántos {product?.returnableName?.toLowerCase()} tenes para
            devolver?
          </h4>
        </div>
        <div className="product-buttons-group">
          <div
            onClick={() => decrementEmptyBag()}
            disabled={product?.returnableQuantity === 0}
            className={`product-bag_button ${
              product?.returnableQuantity > 0 ? "active-button" : ""
            }`}
          >
            -
          </div>
          <span className="product-quantity">
            {product?.returnableQuantity ? product?.returnableQuantity : 0}
          </span>
          <div
            className={`product-bag_button ${
              product?.returnableQuantity > 0 ? "active-button" : ""
            }`}
            onClick={() => incrementEmptyBag()}
          >
            +
          </div>
        </div>
      </div>
      <p className={isPickUp ? "d-none": ""}>
        En caso de no tener se sumarán ${product?.returnablePrice} por
        cada {product?.returnableName} al pedido
      </p>
    </div>
  );
}

export default EmptyBag;
