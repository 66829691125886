import React, { useState, useEffect } from "react";
import "./confirmation.css";
import pointsServices from "../../../../services/api_services/pointsService";
import { calculateTotalWithDiscount } from "../../../../utils";
import { useConfigsContext } from "../../../../context/configsContextProvider";
import { ORDER_TYPES } from "../../../../actions/orderActions";
import { MODALITY_CONSTANTS, PATH_STEPS } from "../../../../constants";
import { useHistory } from "react-router-dom";
import CurrentPromotions from "./CurrentPromotions/CurrentPromotions";
import Subtitle from "../../components/Subtitle";
import ContainerPaymentMethods from "./TypePaymentMethod/ContainerPaymentMethods";

function Confirmation({
  client,
  order = [],
  productsCart,
  discount,
  dispatch,
  setAutoConfirmOrder,
  modality,
  setIsComplete,
}) {
  const { configs } = useConfigsContext();
  const [point, setPoint] = useState();
  const [total, setTotal] = useState(null);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [existReturnableInOrder, setExistReturnableInOrder] = useState(false);
  const [deliveryIsFree, setDeliveryIsFree] = useState(false);
  const [typePayment, setTypePayment] = useState();

  const history = useHistory();
  const navigateToPath = (path) => {
    history.push(path);
  };

  useEffect(() => {
    if (order.point !== null) {
      async function fetchPoint(point) {
        return await pointsServices.getOnePoint(point);
      }
      const orderPoint = order.point?.split("/").slice(-1)[0];
      fetchPoint(orderPoint).then((res) => {
        setPoint(res.data);
      });
    }
  }, []);

  useEffect(() => {
    if (order) {
      setExistReturnableInOrder(
        productsCart.some(({ isReturnable }) => isReturnable)
      );
    }
  }, []);

  useEffect(() => {
    // cambiar la url en el navegador
    if (modality === MODALITY_CONSTANTS.RETIREMENT) {
      navigateToPath(PATH_STEPS.CONFIRMAR_PUNTO_CLICK);
    } else if (modality === MODALITY_CONSTANTS.DELIVERY) {
      navigateToPath(PATH_STEPS.CONFIRMAR_DELIVERY);
    }
  }, [modality]);

  /* Agrega el monto minimo del delivery gratis en la orden */
  useEffect(() => {
    if (order.type === "DELIVERY") {
      dispatch({
        type: ORDER_TYPES.ADD_DELIVERY_MIN_AMOUNT_IN_ORDER,
        payload: Number(configs["DELIVERY_MIN_AMOUNT"].value),
      });
      setIsComplete(false);
    }

  }, []);

  useEffect(() => {
    if (!order.paymentMethod /* && order.type === "DELIVERY" */ && total) setIsComplete(false);
    else setIsComplete(true);
  }, [order]);

  useEffect(() => {
    let products = productsCart.map((product) => {
      return product.price * product.orderQuantity;
    });
    let totalProducts = products.reduce((acum, num) => acum + num);
    setDiscountAmount(
      totalProducts -
        calculateTotalWithDiscount(discount.type, discount.value, discount.maxDiscount, totalProducts)
    );

    let finalTotal = null;
    //Si hay precio de envio y el tipo es delivery
    if (Number(configs["DELIVERY_COST"]) > 0 && order.type !== "PICKUP") {

        if (
          configs["DELIVERY_MIN_AMOUNT"].active &&
          totalProducts >= Number(configs["DELIVERY_MIN_AMOUNT"].value)
        ) {
          finalTotal = calculateTotalWithDiscount(
            discount.type,
            discount.value,
            discount.maxDiscount,
            totalProducts
          );
          setDeliveryIsFree(true);

          setTotal(finalTotal);
        } else {
          totalProducts += Number(configs["DELIVERY_COST"]);
          finalTotal = calculateTotalWithDiscount(
            discount.type,
            discount.value,
            discount.maxDiscount,
            totalProducts
          );
          setDeliveryIsFree(false);

          setTotal(finalTotal);
        }

    } else {
 
        finalTotal = calculateTotalWithDiscount(
          discount.type,
          discount.value,
          discount.maxDiscount,
          totalProducts
        );
        setTotal(finalTotal);
      }

  }, [configs["DELIVERY_COST"], discount]);

  useEffect(() => {
    if (total <= 0 && order?.type === "DELIVERY") {
      setAutoConfirmOrder(true);
    } else {
      setAutoConfirmOrder(false);
    }
  }, [total]);

  useEffect(() => {
    dispatch({
      type: ORDER_TYPES.ADD_PAYMENT_METHOD_IN_ORDER,
      payload: typePayment,
    });
  }, [typePayment]);

  return (
    <div className="confirmation">
      {order.type === "DELIVERY" ? (
        <div className="confirmation-title">
          Chequea tu pedido y pagalo online
        </div>
      ) : (
        <div className="confirmation-title">
          Chequea tu pedido y pagalo online
        </div>
      )}

      <h4 className="form-subtitle-confirmation">Datos de contacto</h4>
      <p>{client.name}</p>
      <p>{client.email}</p>
      <p>
        {client.areaCode} - {client.phone}
      </p>

      {order.type === "DELIVERY" && (
        <div>
          <h4 className="form-subtitle-confirmation">Dirección</h4>
          <p>
            {order.address.street} {order.address.streetNumber}
          </p>
          <p>{order.address.city}</p>
          <p>{order.address.floor}</p>
          <p>{order.address.additionalData}</p>
        </div>
      )}

      <h4 className="form-subtitle-confirmation">Pedido</h4>
      {productsCart
      .sort((a,b) => b.price - a.price)
      .map((product, index) => (
        <div key={index}>
          <div className="item-prices">
            <span className={product.category.name === "retornables" ? "text-custom-darkred" : ""}>
              {product.category === "bag"
                ? product.orderQuantity > 1
                  ? `${product.orderQuantity} Bolsones`
                  : `${product.orderQuantity} ${product.name}`
                : `${product.orderQuantity} ${product.name} ${product?.quantity || ""} ${product?.unit || ""}`}
            </span>
            <span className={product.category.name === "retornables" ? "text-custom-darkred" : ""}>
              ${product.price * product.orderQuantity}
            </span>
          </div>
          
        </div>
      ))}

      {discount.value > 0 && (
        <>
          <div className="item-prices">
            <span className="text-red">Descuento</span>
            <span className="text-red">- ${discountAmount} </span>
          </div>
          <div className="item-prices">
            <span className="text-red">
              CUPÓN UTILIZADO: {order.discountcode}
            </span>
          </div>
        </>
      )}
      {order.type === "DELIVERY" && (
        <div>
          <h4 className="form-subtitle-confirmation">Fecha de entrega</h4>
          <p>{order.address.day}</p>
        </div>
      )}
      {order.type === "PICKUP" && (
        <>
          <h4 className="form-subtitle-confirmation">Punto Click</h4>
          <p>{point?.name}</p>
          <p>{point?.address}</p>
          <p>
            {order.pointday} {point?.openHours}
          </p>
        </>
      )}
      {order.type === "DELIVERY" && !deliveryIsFree && (
        <div className="item-prices deliveryCost">
          <h4 className="form-subtitle-confirmation">Valor de envío</h4>
          <span>${configs["DELIVERY_COST"]}</span>
        </div>
      )}

      {order.type === ["DELIVERY"].includes(order.type) && deliveryIsFree && (
        <div className="item-prices deliveryCost">
          <h4 className="form-subtitle-confirmation">Valor de envío</h4>
          <span className="text-red">GRATIS</span>
        </div>
      )}

      <hr className="opacity-100" style={{ color: "#C2C2C2" }} />

      {total ? (
        <>
          <Subtitle textContent="Pago (seleccioná una opción)" className="form-subtitle mt-3" />
          <ContainerPaymentMethods
            typePayment={typePayment}
            setTypePayment={setTypePayment}
          />
        </>
      ) : null}

      <CurrentPromotions modalityState={modality} />

      <div className="confirmation-total total-box">
        <div className="total">
          <h4>Total</h4>
          <span>${total}</span>
        </div>
        
      </div>
      
    </div>
  );
}

export default Confirmation;
