import React, { useEffect, useState } from "react";
import EmptyBag from "../../../../components/EmptyBag";

export default function ProductCard({
  product,
  delFromCart,
  addToCart,
  extra = false,
  order,
  incrementReturnableQuantity,
  decrementReturnableQuantity,
}) {

  return (
    <div className="producCardContainer">
      <div className="productCardItem">
        <div className="productCardImage">
          <img src={`${product.imageUrl}`} alt={"Imagen producto"} />
        </div>
        <div className="productCardData">
          <div className="productDataContainer">
            <h3 className="productData">
              <span className="productName">{product.name} </span>
            </h3>
            <span className={extra ? "" : "text-red varieties"}>
              {product.quantity} {product.unit} ${product.price}{" "}
              {product.sale && (
                <span className="product__sale_desktop"> Oferta! </span>
              )}{" "}
              {product.sale && (
                <span className="product__sale_mobile"> Oferta! </span>
              )}
            </span>
          </div>
          {product.children?.length > 0 && (
            <div className="comboDescription">
              {product.children.map((item, index) => (
                <p key={item + index} className="varieties">
                  {item} {index + 1 < product.children.length && "+ "}
                </p>
              ))}
            </div>
          )}
        </div>
        <div className="product-buttons-group">
          <div
            onClick={() => product.orderQuantity > 0 && delFromCart(product)}
            disabled={product.orderQuantity === 0}
            className={`product-bag_button ${
              product.orderQuantity > 0 ? "active-button" : ""
            }`}
          >
            -
          </div>
          <span className="product-quantity">{product.orderQuantity}</span>
          <div
            className={`product-bag_button ${
              product.orderQuantity > 0 ? "active-button" : ""
            }`}
            onClick={() => addToCart(product)}
          >
            +
          </div>
        </div>
      </div>
     {/*  {product.isReturnable && product.orderQuantity > 0 && (
        <div className="productCardItem">
          <EmptyBag
            isPickUp={order.type === "PICKUP"}
            product={product}
            addToCart={addToCart}
            incrementReturnableQuantity={incrementReturnableQuantity}
            decrementReturnableQuantity={decrementReturnableQuantity}
          />
        </div>
      )} */}
    </div>
  );
}
