export const CONFIGS_CONSTANTS = {
    "BAG_COST": "BAG_COST", 
    "BOLSITAS": "BOLSITAS", 
    "DAY_A": "DAY_A", 
    "DAY_B": "DAY_B", 
    "DELIVERY_COST": "DELIVERY_COST", 
    "DELIVERY_DAY_A": "DELIVERY_DAY_A", 
    "DELIVERY_DAY_B": "DELIVERY_DAY_B", 
    "DELIVERY_DAY_C": "DELIVERY_DAY_C", 
    "DELIVERY_MIN_AMOUNT": "DELIVERY_MIN_AMOUNT", 
    "MIN_AMOUNT": "MIN_AMOUNT", 
    "MOSTRAR_BOLSON_FORM": "MOSTRAR_BOLSON_FORM", 
    "recaptcha_site_key": "recaptcha_site_key", 
    "SHOW_DELIVERY_OPTION": "SHOW_DELIVERY_OPTION", 
    "SHOW_POINTS_OPTION": "SHOW_POINTS_OPTION", 
}

export const MODALITY_CONSTANTS = {
    "RETIREMENT": "retirement",
    "DELIVERY": "delivery"
}

export const PAYMENT_METHODS_KEYS = {
    MP: "MP",
    MODO: "MODO",
    NAVE: "NAVE",
    CTA_DNI: "CTA_DNI",
    BNA: "BNA",
}

export const PAYMENT_METHODS_TEXT = {
    MP: "Mercado Pago",
    MODO: "Modo",
    NAVE: "Nave",
    CTA_DNI: "Cuenta DNI",
    BNA: "BNA+",
}

export const STEPS = {
    DATOS: 0,
    PEDIDO: 1,
    MODALIDAD: 2,
    CONFIRMAR: 3,
    FINAL: 4,
}

export const PATH_STEPS = {
    DATOS_PUNTO_CLICK: "/paso-1-datos-punto-click",
    DATOS_DELIVERY: "/paso-1-datos-delivery",
    PEDIDO_PUNTO_CLICK: "/paso-2-pedido-punto-click",
    PEDIDO_DELIVERY: "/paso-2-pedido-delivery",
    MODALIDAD_PUNTO_CLICK: "/paso-3-modalidad-punto-click",
    MODALIDAD_DELIVERY: "/paso-3-modalidad-delivery",
    CONFIRMAR_PUNTO_CLICK: "/paso-4-confirmar-punto-click",
    CONFIRMAR_DELIVERY: "/paso-4-confirmar-delivery",
}

export const TAB_CATEGORIES_LIST = [
    { key: "todo", value: "VER TODO", tab: 0 },
    { key: "combos", value: "COMBOS", tab: 1 },
    { key: "suelto", value: "SUELTO", tab: 2 },
    { key: "almacen", value: "ALMACÉN", tab: 3 },
    { key: "otros", value: "OTROS", tab: 4 },
    { key: "retornables", value: "RETORNABLES", tab: 5 },
  ];