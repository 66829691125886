import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { ORDER_TYPES } from "../../../../../actions/orderActions";

const OPTIONS = [
  {
    id: 1,
    text: "Me lo recomendaron",
  },
  {
    id: 2,
    text: "Por las redes sociales",
  },
  {
    id: 3,
    text: "Por la radio",
  },
  {
    id: 4,
    text: "En el almacén donde compro",
  },
  {
    id: 5,
    text: "En una Feria",
  },
  {
    id: 6,
    text: "En una visita a mi empresa",
  },
  {
    id: 7,
    text: "Por un folleto en mi casa",
  },
];

export function HowMeetUsSelect({dispatch}) {
  const [reason, setReason] = React.useState();

  React.useEffect(() => {
    dispatch({
      type: ORDER_TYPES.ADD_ABOUT_IN_ORDER,
      payload: reason,
    });
  }, [reason]);

  return (
    <div className="selectContainer">
      <FormControl variant="standard" className="form-control_delivery-select">
        <InputLabel id="demo-simple-select-standard-label">
          ¿Cómo conociste a EL CLICK?
        </InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          label="Elegí una zona*"
        >
          {OPTIONS?.map(({ id, text }) => (
            <MenuItem key={id} value={text}>
              {text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
