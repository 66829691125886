import React from "react";
import PropTypes from 'prop-types';
import { TextField } from "@material-ui/core";

const FormInput = ({
  type,
  name,
  label,
  value,
  onBlur,
  onChange,
  error,
  errorMsg,
}) => {
  return (
    <div className="col-sm-12 col-md-6 mb-3">
      {type == "email" ? (
        <>
          <TextField
            id="standard-basic"
            type={type}
            label={label}
            name={name}
            variant="standard"
            value={value}
            onBlur={(e) => onBlur(e)}
            onChange={(e) => onChange(e)}
          />
          {error && <span className="text-danger">{errorMsg}</span>}
        </>
      ) : (
        <>
          <TextField
            type={type}
            id="standard-name"
            label={label}
            name={name}
            variant="standard"
            value={value}
            InputLabelProps={value ? { shrink: true } : {}}
            onBlur={() => {
              return !error && onBlur();
            }}
            onChange={(e) => onChange(e)}
          />
          {error && <span className="text-danger">{errorMsg}</span>}
        </>
      )}
    </div>
  );
};

FormInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
}

export default FormInput;
