import connect from "./axios";

const orderServices = {
  sendOrder: async (order) => {
    let response = [];
    await connect
      .post("/orders", order)
      .then((order) => {
        response.push(order.data);
      })
      .catch((err) => console.log(err));
    return response;
  },
};
export default orderServices;
