export const PENDIENT_ORDERS = [
    {
        id: 1,
        clientId: 1,
        order: JSON.stringify({
            "client":"/api/clients/1",
            "point":"/api/points/40",
            "pointday":"Martes 14/6",
            "address":
            {
                "day":"Martes 14/6 de 14 a 21 hs",
                "street":"Crisologo Larralde 5951",
                "city":"Wilde",
                "floor":"-",
                "additionalData":"",
                "pc":"1870"
            },
            "type":"PICKUP",
            "products":
            [
                {
                    "product":
                    "/api/products/257",
                    "quantity":1
                },
                {
                    "product":"/api/products/244",
                    "quantity":3
                },
                {
                    "product":"/api/products/223",
                    "quantity":2
                }
            ],
            "config":"/api/configs/DELIVERY_COST",
            "bagid":"244"
        })
    },
]