import React from "react";
import "./index.css";

export default function ItemPromotion({icons,items,name}) {   
    return (
      <div className="pay-method col-12 col-lg-6 w-100">
          <h6 className="d-flex align-items-center gap-1 my-0">
             {icons?.map((icon) => <img key={icon} className="icon-promotion" src={icon} alt={"icon"} />)}
              <span>{name}</span>
          </h6>
          <ul className="promotions-list">
              {items.map((item) => (
                  <li className="w-100" key={item}>• {item}</li>
          ))}
        </ul>
      </div>
    );
}
