export const ORDER_TYPES = {
    ADD_USER_IN_ORDER: "ADD_USER_IN_ORDER",
    ADD_MODALITY_IN_ORDER: "ADD_MODALITY_IN_ORDER",
    ADD_POINT_IN_ORDER: "ADD_POINT_IN_ORDER",
    ADD_PRODUCTS_IN_ORDER: "ADD_PRODUCTS_IN_ORDER",
    ADD_ADDRESS_IN_ORDER: "ADD_ADDRESS_IN_ORDER",
    ADD_TYPE_IN_ORDER: "ADD_TYPE_IN_ORDER",
    CLEAR_ORDER: "CLEAR_ORDER",
    ADD_EMPTY_BAG_IN_ORDER: "ADD_EMPTY_BAG_IN_ORDER",
    ADD_DISCOUNT_IN_ORDER: "ADD_DISCOUNT_IN_ORDER",
    ADD_POINTDAY_IN_ORDER: "ADD_POINTDAY_IN_ORDER",
    ADD_BAG_ID_IN_ORDER: "ADD_BAG_ID_IN_ORDER",
    ADD_DELIVERY_MIN_AMOUNT_IN_ORDER: "ADD_DELIVERY_MIN_AMOUNT_IN_ORDER",
    ADD_ABOUT_IN_ORDER: "ADD_ABOUT_IN_ORDER",
    ADD_PERIOD_IN_ORDER: "ADD_PERIOD_IN_ORDER",
    ADD_PAYMENT_METHOD_IN_ORDER: "ADD_PAYMENT_METHOD_IN_ORDER"
}