import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import couponServices from "../../../../../services/api_services/couponService";
import Subtitle from "../../../components/Subtitle";

const Discount = ({
  clientState,
  setDiscount,
  discount,
  total,
  totalDeProductos,
}) => {
  const [coupon, setCoupon] = useState({
    response: null,
    error: false,
    isSuccess: false,
    message: "",
  });

  const [clientCoupon, setClientCoupon] = useState("");

  useEffect(() => {
    if ((coupon.isSuccess || coupon.error) && totalDeProductos === 0) {
      setCoupon({
        response: null,
        error: true,
        isSuccess: false,
        message: "Vuelve a ingresar el cupón",
      });
      setDiscount({
        ...discount,
        type: "",
        value: 0,
        code: "",
      });
      setClientCoupon("");
      return;
    }

    if (coupon.isSuccess || coupon.error) {
      handleCouponBlur();
      return;
    }
  }, [totalDeProductos]);

  async function handleCouponBlur() {
    const requestResponse = await couponServices.sendCoupon({
      code: clientCoupon,
      email: clientState.email,
    });

    const { status, data } = requestResponse.data;
    if (status === "true") {
      const { type, minvalue, value, maxDiscount } = data;
      if (minvalue > totalDeProductos) {
        setCoupon({
          response: null,
          error: true,
          isSuccess: false,
          message:
            "El total de compra mínimo para usar éste cupón es " + minvalue,
        });
        setDiscount({
          ...discount,
          type: "",
          value: 0,
          code: "",
        });
        /* setClientCoupon(""); */
      } else {

        let message;
        if(maxDiscount < totalDeProductos && maxDiscount) 
          message =  type === "%" ? `${value}% de descuento con un tope de $${maxDiscount}` : `$${value} con un tope de $${maxDiscount}`;
        else
          message = "Descuento aplicado! " + (type === "%" ? `${value}%`: `$${value}`) + " de descuento";
        
        setDiscount({
          ...discount,
          type: type,
          value: value,
          code: clientCoupon,
          maxDiscount
        });
        setCoupon({
          ...coupon,
          response: data,
          isSuccess: true,
          error: false,
          message
        });
      }
    } else {
      setDiscount({
        ...discount,
        type: "",
        value: 0,
        code: "",
      });
      setCoupon({
        response: null,
        error: true,
        isSuccess: false,
        message: "Cupón no válido",
      });
      setClientCoupon("");
    }
  }

  function handleCouponChange (e) {
    setClientCoupon(e.target.value)
  }
  return (
    <div className="discountField">
      <Subtitle
        textContent="Cupón de descuento"
        className="form-subtitle_order"
      />
      <p className="tip-paragraph">
        Ingresa tu cupón debajo y será descontado del total del pedido
      </p>
      <div className="col-sm-12 col-md-6">
        <TextField
          id="standard-basic"
          disabled={total <= 0}
          type="text"
          label="Cupón de descuento"
          name="discount"
          variant="standard"
          value={clientCoupon}
          onChange={(e) => handleCouponChange(e)}
          onBlur={() => handleCouponBlur()}
        />
        {coupon.error && <span className="text-danger">{coupon.message}</span>}
        {coupon.isSuccess && (
          <span className="coupon-success">{coupon.message}</span>
        )}
      </div>
    </div>
  );
};

Discount.propTypes = {
  clientState: PropTypes.object,
};

export default Discount;
