import { TAB_CATEGORIES_LIST } from "../constants";

export const incrementProductQuantity = (product) => {
  return (product.orderQuantity = product.orderQuantity + 1);
};

export const decrementProductQuantity = (product) => {
  if (product.orderQuantity > 0) {
    return (product.orderQuantity = product.orderQuantity - 1);
  }
};

export const calculateTotalWithDiscount = (
  discountType,
  discountValue,
  discountLimit,
  amount
) => {
  let discount =
    discountType === "%"
      ? (amount * discountValue) / 100
      : discountType === "$"
      ? discountValue
      : 0;
  let result =
    discountLimit && discount > discountLimit
      ? amount - discountLimit
      : amount - discount;
  return result < 0 ? 0 : result;
};

export const ordenarPorDiaYNombre = (activePoints) => {
  activePoints.sort(function (a, b) {
    if (a.days[0] > b.days[0]) {
      return 1;
    }
    if (a.days[0] < b.days[0]) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });
  let pointsDays = activePoints.map((point) => point.days[0]);
  let uniqueDays = new Set(pointsDays);
  let ordenados = [];
  uniqueDays?.forEach((day) => {
    let filtradosPorDia = activePoints.filter((point) => point.days[0] === day);
    let ordenadosPorNombre = filtradosPorDia.sort(function (a, b) {
      if (a.name.toUpperCase() > b.name.toUpperCase()) {
        return 1;
      }
      if (a.name.toUpperCase() < b.name.toUpperCase()) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    ordenados = [...ordenados, ordenadosPorNombre];
  });
  return ordenados.flat();
};
export const orderByName = (activePoints) => {
  let pointsOrderByName = activePoints.sort(function (a, b) {
    if (a.name.toUpperCase() > b.name.toUpperCase()) {
      return 1;
    }
    if (a.name.toUpperCase() < b.name.toUpperCase()) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });
  return pointsOrderByName;
};

export const capitalLetter = (string) => {
  let word = string.toLowerCase();
  word = word.replace(word[0], word[0].toUpperCase());
  return word;
};

export const scrollTo = (elementId, place) => {
  let element = document.querySelector(`#${elementId}`);
  element.scrollIntoView({ block: `${place}`, behavior: "smooth" });
};

export const comparar = (a, b) => {
  return a.orden - b.orden;
};

export function filterActiveProducts(products, orderModality) {
  // Filtrar los productos activos
  const activeProducts = products.filter(
    (product) => product.category !== null && product[orderModality]
  );

  // Agregar la propiedad orderQuantity con valor 0 a cada producto
  const productsWithOrderQuantity = activeProducts.map((product) => ({
    ...product,
    orderQuantity: 0,
  }));

  // Ordenar los productos por la propiedad 'orden'
  return productsWithOrderQuantity.sort((a, b) => a.orden - b.orden);
}

export function groupProducts(products) {
  // Agrupar los productos por la propiedad 'category'

  const groupedProducts = TAB_CATEGORIES_LIST.reduce(
    (acc, category) => ({
      ...acc,
      [category.key]: products.filter(product => product?.category?.name === category.key),
    }),
    {}
  )

  return groupedProducts;
}
/* Una función que devuelve una matriz de objetos con la identificación y la cantidad de los
  productos en el pedido. */
export const getProductsInPendientOrder = (listOfProductsInPendientOrder) => {
  const productIdPositionInString = 14; // "product": "/api/products/257"
  return listOfProductsInPendientOrder.map((item) => {
    return {
      id: item.product.slice(productIdPositionInString),
      quantity: item.quantity,
    };
  });
};

export const setPropOrderQuantityInProducts = (
  productsInPendientOrder,
  productsInDb
) => {
  const productsWithModifiedPendingOrderProperty = [];
  productsInPendientOrder.forEach((item) => {
    let product = productsInDb.find((prod) => prod.id === item.id);
    if (typeof product !== "undefined") {
      product.orderQuantity = item.quantity;
      productsWithModifiedPendingOrderProperty.push(product);
    }
  });

  return productsWithModifiedPendingOrderProperty;
};

export const orderElements = (arrWithData, arrOrder) => {
  const newObj = {};
  arrOrder.forEach((day) => {
    newObj[day] = arrWithData[day];
  });
  return newObj;
};


export const converterPricesForReturnables = (products) => 
   products.map((product) => {
    if(product.category.name === "retornables"){
      return {
        ...product,
        price: product.price * (product.price > 0 ? -1 : 1)
      }
    }
    return product
  })
