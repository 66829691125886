import { ORDER_TYPES } from "../actions/orderActions";

export const orderInitialState = {
  client: "",
  point: null,
  pointday: "",
  address: {},
  type: "",
  products: [
    {
      product: "",
      quantity: 0,
      isReturnable: false,
      returnableQuantity: "",
    },
  ],
  config: "/api/configs/DELIVERY_COST",
  discountcode: "",
  bagid: 0,
  paymentMethod: "",
  emptybag: {
    quantity: 0,
    price: 0,
  },
  /* emptybags: 0, */
  deliveryMinAmount: 0,
  about: "",
  periodId: 0
};

export function orderReducer(state, action) {
  switch (action.type) {
    case ORDER_TYPES.ADD_USER_IN_ORDER: {
      let client = action.payload;
      return client.length !== 0
        ? {
            ...state,
            client: `/api/clients/${client.id}`
          }
        : orderInitialState;
    }
    case ORDER_TYPES.ADD_POINT_IN_ORDER: {
      let point = action.payload;
      return point !== null
        ? {
            ...state,
            point: `/api/points/${point}`
          }
        : state;
    }
    case ORDER_TYPES.ADD_PRODUCTS_IN_ORDER: {
      let products = action.payload;
      return products !== null
        ? {
            ...state,
            products: products
          }
        : state;
    }
    case ORDER_TYPES.ADD_ADDRESS_IN_ORDER: {
      let address = action.payload;
      return address !== null
        ? {
            ...state,
            address: address
          }
        : state;
    }
    case ORDER_TYPES.ADD_TYPE_IN_ORDER: {
      let type = action.payload;
      return type !== null
        ? {
            ...state,
            type: type
          }
        : state;
    }
    /* case ORDER_TYPES.ADD_EMPTY_BAG_IN_ORDER: {
      let empty_bag = action.payload;
      return empty_bag !== null
        ? {
            ...state,
            emptybag: empty_bag,
            emptybags: empty_bag.quantity
          }
        : state;
    } */
    case ORDER_TYPES.ADD_DISCOUNT_IN_ORDER: {
      let discount = action.payload;
      return discount !== null
        ? {
            ...state,
            discountcode: discount.code,
          }
        : state;
    }

    case ORDER_TYPES.ADD_POINTDAY_IN_ORDER: {
      let pointday = action.payload;
      return pointday !== null
        ? {
            ...state,
            pointday: pointday,
          }
        : state;
    }

    case ORDER_TYPES.ADD_BAG_ID_IN_ORDER: {
      let bagid = action.payload;
      return bagid !== null
        ? {
            ...state,
            bagid: bagid,
          }
        : state;
    }

    case ORDER_TYPES.ADD_DELIVERY_MIN_AMOUNT_IN_ORDER: {
      let deliveryMinAmount = action.payload;
      return deliveryMinAmount !== null
        ? {
            ...state,
            deliveryMinAmount: deliveryMinAmount,
          }
        : state;
    }

    case ORDER_TYPES.ADD_ABOUT_IN_ORDER: {
      let about = action.payload;
      return about !== null
        ? {
            ...state,
            about,
          }
        : state;
    }
    case ORDER_TYPES.ADD_PERIOD_IN_ORDER: {
      let periodId = action.payload;
      return periodId !== null
        ? {
            ...state,
            periodId: Number(periodId),
          }
        : state;
    }

    case ORDER_TYPES.CLEAR_ORDER: {
     return orderInitialState
    }

    case ORDER_TYPES.ADD_PAYMENT_METHOD_IN_ORDER: {
      const typePayment = action.payload;
      return typePayment !== null
      ? {
        ...state,
        paymentMethod: typePayment
      } 
      : state
    }

    default:
      return state;
  }
}
