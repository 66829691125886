import React, {
  createContext,
  useState,
  useCallback,
  useMemo,
  useContext,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import configServices from "../services/api_services/configService";
import { PATH_STEPS } from "../constants";

export const ConfigsContext = createContext(); //Devuelve un objeto con la propiedad provider.

export function ConfigsContextProvider({ children }) {
  //Obtener las configuraciones
  //Setearlas en un estado
  const [configs, setConfigs] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if(Object.values(PATH_STEPS).includes(location.pathname)){
      location.href = "/"
    }
  }, []);

  const getConfigsData = useCallback(() => {
    setIsLoading(true);
    const fetchConfigs = async () => {
      return await configServices.getConfig();
    };

    fetchConfigs().then((configs) => {
        const configsObject = {};
        configs.forEach(({id, value, active}) => {
            if(id === "MOSTRAR_BOLSON_FORM"){
                configsObject[`${id}`] = active
            } 
            else if(
              /^DELIVERY_DAY_[A-Z]/.test(id) ||
              /^POINTS_OPTION_*/.test(id) ||
              /^DELIVERY_OPTION_*/.test(id) ||
              id === "DELIVERY_MIN_AMOUNT" ||
              id === "SHOW_DELIVERY_OPTION" ||
              id === "SHOW_POINTS_OPTION" || 
              id === "ORDER_SUBTITLE_RETURNABLES"
              /* id === "POINTS_OPTION_TITLE" ||
              id === "POINTS_OPTION_SUBTITLE" ||
              id === "POINTS_OPTION_DISABLED_SUBTITLE" ||
              id === "DELIVERY_OPTION_TITLE" ||
              id === "DELIVERY_OPTION_SUBTITLE" ||
              id === "DELIVERY_OPTION_DISABLED_SUBTITLE" */
              ){
                configsObject[`${id}`] = {
                  value,
                  active
                }
            }else{
                configsObject[`${id}`] = value
            }
        })
        setConfigs(configsObject);
        setIsLoading(false)
    })
  });

  const value = useMemo(
    () => ({
        getConfigsData,
        configs,
        isLoading
    }),
    [getConfigsData, configs, isLoading]
  );

  return (
    <ConfigsContext.Provider value={value}>{children}</ConfigsContext.Provider>
  );
}

ConfigsContextProvider.propTypes = {
  children: PropTypes.object,
};

export const useConfigsContext = () => {
  return useContext(ConfigsContext);
};
