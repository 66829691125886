import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/* Components */
import { RadioGroup, FormControl } from "@material-ui/core";
import ModalityItem from "./ModalityItem";
import deliveryService from "../../../../../services/api_services/deliveryService";
import { useConfigsContext } from "../../../../../context/configsContextProvider";
import {
  CONFIGS_CONSTANTS,
  MODALITY_CONSTANTS,
} from "../../../../../constants";
import { ModalityDisabled } from "./ModallityDisabled";
import ModalitySkeleton from "./ModalitySkeleton";

const ModalityContainer = ({ modality, setModality }) => {
  const { configs, isLoading } = useConfigsContext();

  const {
    SHOW_POINTS_OPTION,
    SHOW_DELIVERY_OPTION,
    POINTS_OPTION_TITLE,
    POINTS_OPTION_SUBTITLE,
    POINTS_OPTION_DISABLED_SUBTITLE,
    DELIVERY_OPTION_TITLE,
    DELIVERY_OPTION_SUBTITLE,
    DELIVERY_OPTION_DISABLED_SUBTITLE,
  } = configs;

  if (isLoading) {
    return (
      <>
        <ModalitySkeleton />
        <ModalitySkeleton />
      </>
    );
  }

  
  useEffect(() => {
    if (SHOW_POINTS_OPTION !== undefined && !SHOW_POINTS_OPTION.active || 
      SHOW_DELIVERY_OPTION !== undefined && !SHOW_DELIVERY_OPTION.active) {
        setModality("")
    }
  }, [configs])

  return (
    <div>
      <FormControl component="fieldset" className="radio-container">
        <RadioGroup defaultValue="retirement" name="radio-buttons-group">
        {SHOW_DELIVERY_OPTION !== undefined && SHOW_DELIVERY_OPTION.active ? (
            <ModalityItem
              modality={modality}
              setModality={setModality}
              value={MODALITY_CONSTANTS.DELIVERY}
              label={DELIVERY_OPTION_TITLE.value}
              info={DELIVERY_OPTION_SUBTITLE.value}
            />
          ) : (
            <ModalityDisabled
              label={DELIVERY_OPTION_TITLE?.value || ""}
              info={DELIVERY_OPTION_DISABLED_SUBTITLE?.value || ""}
            />
          )}

          {SHOW_POINTS_OPTION !== undefined && SHOW_POINTS_OPTION.active ? (
            <ModalityItem
              modality={modality}
              setModality={setModality}
              value={MODALITY_CONSTANTS.RETIREMENT}
              label={POINTS_OPTION_TITLE.value}
              info={POINTS_OPTION_SUBTITLE.value}
            />
          ) : (
            <ModalityDisabled
              label={POINTS_OPTION_TITLE?.value || ""}
              info={POINTS_OPTION_DISABLED_SUBTITLE?.value || ""}
            />
          )}
 
        </RadioGroup>
      </FormControl>
    </div>
  );
};

ModalityContainer.propTypes = {
  modality: PropTypes.string,
  setModality: PropTypes.func,
};

export default ModalityContainer;
