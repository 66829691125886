import React, { useState, useReducer, useEffect, useRef } from "react";
import "./form.css";
import { orderInitialState, orderReducer } from "../../reducers/orderReducer";
import { ORDER_TYPES } from "../../actions/orderActions";
import orderServices from "../../services/api_services/orderService";
import {
  clientInitialState,
  clientReducer,
} from "../../reducers/clientReducer";
import { TYPES } from "../../actions/clientActions";
import { CART_TYPES } from "../../actions/productsCartActions";
import {
  productsCartIntialState,
  productsCartReducer,
} from "../../reducers/productsCartReducer";

/* Components */
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import UserData from "./steps/UserData";
import Order from "./steps/Order";
import Confirmation from "./steps/Confirmation";
import Delivery from "./steps/Modality/Delivery/Delivery";
import Point from "./steps/Modality/Point";
import { Box, CircularProgress } from "@material-ui/core";
import FormButtons from "./components/FormButtons";
import { useConfigsContext } from "../../context/configsContextProvider";
import {
  ProductsContextProvider,
  useProductsContext,
} from "../../context/productsContextProvider";
import { PendientOrderContextProvider } from "../../context/pendientOrderContextProvider";
import ModalConfirm from "./components/ModalConfirm";
import clientsServices from "../../services/api_services/clientsService";
import { ClientContextProvider } from "../../context/clientProvider";
import { MODALITY_CONSTANTS, STEPS } from "../../constants";
import OrderWrapper from "./steps/Order/NewCart";
import periodServices from "../../services/api_services/periodService";

function getSteps() {
  return ["Datos", "Pedido", "Modalidad", "Confirmar"];
}

const Form = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const steps = getSteps();
  const [isComplete, setIsComplete] = useState(false);
  const [client, setClient] = useState({});
  const [modality, setModality] = useState(MODALITY_CONSTANTS.DELIVERY);
  const [selectedPoint, setSelectedPoint] = useState({
    id: null,
    openDay: null,
  });
  const [productsOrder, setProductsOrder] = useState({});
  const [discount, setDiscount] = useState({
    type: "",
    value: null,
  });
  const [productsCart, setProductsCart] = useState();
  const [address, setAddress] = useState({});
  const [orderResponse, setOrderResponse] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [showFormErrorsMessage, setShowFormErrorsMessage] = useState(false);

  const [state, dispatch] = useReducer(orderReducer, orderInitialState);
  const [clientState, clientDispatch] = useReducer(
    clientReducer,
    clientInitialState
  );
  const [cartState, cartDispatch] = useReducer(
    productsCartReducer,
    productsCartIntialState
  );
  const [pendientOrderClientId, setPendientOrderClientId] = useState(null);
  const [autoConfirmOrder, setAutoConfirmOrder] = useState(false);

  const refsTotalObjetiveSticky = {
    form: useRef(null),
    containerTotal: useRef(null),
    itemTotal: useRef(null),
    formButtons: useRef(null),
  }

  const { getConfigsData, configs } = useConfigsContext();
  /* En caso de link de orden pendiente, obtiene el id del cliente para setear el estado */
  useEffect(() => {
    getConfigsData();
    let params = new URLSearchParams(document.location.search);
    let clientId = params.get("client");
    if (clientId) {
      const getClient = async () => await clientsServices.getById(clientId);
      getClient().then((res) => {
        clientDispatch({ type: TYPES.SEARCH_CLIENT, payload: [{ ...res }] });
      });
      setPendientOrderClientId(clientId);
    }
  }, []);

  useEffect(async () => {
    async function fetchToGetPeriodId () {
      return await periodServices.getCurrentPeriod();
    }

    try {
      const { data } = await fetchToGetPeriodId();
      dispatch({ type: ORDER_TYPES.ADD_PERIOD_IN_ORDER, payload: data[0].id})
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    dispatch({ type: ORDER_TYPES.ADD_USER_IN_ORDER, payload: clientState });
  }, [clientState]);

  useEffect(() => {
    if (typeof selectedPoint.id !== undefined) {
      dispatch({
        type: ORDER_TYPES.ADD_POINT_IN_ORDER,
        payload: selectedPoint?.id,
      });
      dispatch({
        type: ORDER_TYPES.ADD_POINTDAY_IN_ORDER,
        payload: selectedPoint?.openDay,
      });
    }
  }, [selectedPoint]);

  useEffect(() => {
    if (modality === MODALITY_CONSTANTS.DELIVERY) {
      dispatch({ type: ORDER_TYPES.ADD_TYPE_IN_ORDER, payload: "DELIVERY" });
    } else {
      dispatch({ type: ORDER_TYPES.ADD_TYPE_IN_ORDER, payload: "PICKUP" });
    }
    
  }, [modality]);

  useEffect(() => {
    dispatch({
      type: ORDER_TYPES.ADD_PRODUCTS_IN_ORDER,
      payload: productsOrder,
    });
  }, [productsOrder]);

  useEffect(() => {
    dispatch({ type: ORDER_TYPES.ADD_DISCOUNT_IN_ORDER, payload: discount });
  }, [discount]);

  useEffect(() => {
    let clientAddress = {
      day: clientState.day,
      street: clientState.street,
      streetNumber: clientState.streetNumber,
      city: clientState.city,
      floor: clientState.floor ? clientState.floor : "-",
      additionalData: clientState.additionalData,
      pc: clientState.postal_code,
      modalityAddressSrc: clientState.modalityAddressSrc
    };
    dispatch({
      type: ORDER_TYPES.ADD_ADDRESS_IN_ORDER,
      payload: clientAddress,
    });
  }, [clientState]);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <UserData
            setIsComplete={setIsComplete}
            isComplete={isComplete}
            client={client}
            setClient={setClient}
            modality={modality}
            setModality={setModality}
            TYPES={TYPES}
            clientDispatch={clientDispatch}
            clientState={clientState}
            pendientOrderClientId={pendientOrderClientId}
            setShowFormErrorsMessage={setShowFormErrorsMessage}
          />
        );
      case 1:
        return (
          <Order
            productsOrder={productsOrder}
            setProductsOrder={setProductsOrder}
            order={state}
            setProductsCart={setProductsCart}
            CART_TYPES={CART_TYPES}
            cartDispatch={cartDispatch}
            cartState={cartState}
            ORDER_TYPES={ORDER_TYPES}
            dispatch={dispatch}
            clientState={clientState}
            discount={discount}
            setDiscount={setDiscount}
            isComplete={isComplete}
            setIsComplete={setIsComplete}
            modality={modality}
            refsTotalObjetiveSticky={refsTotalObjetiveSticky}
          />
        );

      case 2:
        return modality === MODALITY_CONSTANTS.RETIREMENT ? (
          <Point
            isComplete={isComplete}
            setIsComplete={setIsComplete}
            setSelectedPoint={setSelectedPoint}
            selectedPoint={selectedPoint}
            order={state}
            setShowFormErrorsMessage={setShowFormErrorsMessage}
            modality={modality}
            clientDispatch={clientDispatch}
            TYPES={TYPES}
          />
        ) : (
          <Delivery
            address={address}
            setAddress={setAddress}
            clientState={clientState}
            clientDispatch={clientDispatch}
            TYPES={TYPES}
            client={client}
            setClient={setClient}
            setIsComplete={setIsComplete}
            showFormErrors={showFormErrors}
            setShowFormErrors={setShowFormErrors}
            order={state}
            setShowFormErrorsMessage={setShowFormErrorsMessage}
            modality={modality}
          />
        );
      case 3:
        return (
          <Confirmation
            key={"confirmation"}
            client={client}
            dispatch={dispatch}
            order={state}
            productsCart={productsCart}
            setIsComplete={setIsComplete}
            discount={discount}
            setAutoConfirmOrder={setAutoConfirmOrder}
            modality={modality}
          />
        );
    }
  }

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  useEffect(() => {
    if (activeStep === STEPS.FINAL) {
      async function fetchOrder() {
        return await orderServices.sendOrder(state);
      }
      fetchOrder().then((res) => {
        if (res[0] !== undefined) {
          /* if (res[0].type === "PICKUP" || (res[0].type === "DELIVERY" && autoConfirmOrder)) {
            return (window.location.href = `http://${window.location.host}/order/${res[0].code}`);
          } */
          setOrderResponse(res);
          setLoading(false);
        }
      });
    } 
  }, [activeStep]);

  useEffect(() => {
     /* if (
      orderResponse &&
      ["DELIVERY", "PICKUP"].includes(orderResponse[0]?.type) &&
      !autoConfirmOrder
      ) {   */
      if (orderResponse && orderResponse[0] && orderResponse[0].link !== undefined && orderResponse[0].price) {
        return (window.location.href = orderResponse[0].link);
      } else {
        if(orderResponse.length > 0){
          return (window.location.href = `http://${window.location.host}/order/${orderResponse[0].code}`);
        }
        return;
      }  
    /* }  */
  }, [orderResponse]);

  return (
    <div className="form" ref={refsTotalObjetiveSticky.form}>
      <h2 className="title">HACÉ TU PEDIDO</h2>
      { activeStep === STEPS.PEDIDO && <h2 className="subtitle__min_amount">MÍNIMO DE COMPRA ${configs.MIN_AMOUNT}</h2> }
      <Box sx={{ width: "100%" }}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          className="form-stepper"
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel className="step" {...labelProps}>
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
      <div>
        {activeStep === steps.length ? (
          <div>
            <div className="instructions">
              {loading && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <CircularProgress />
                  <p>Estamos procesando tu pedido</p>
                </Box>
              )}
            </div>
          </div>
        ) : (
          <ClientContextProvider>
            <ProductsContextProvider cartState={cartState} modality={modality}>
              <PendientOrderContextProvider
                CART_TYPES={CART_TYPES}
                cartDispatch={cartDispatch}
                setSelectedPoint={setSelectedPoint}
                ORDER_TYPES={ORDER_TYPES}
                dispatch={dispatch}
                setActiveStep={setActiveStep}
                setModality={setModality}
                clientDispatch={clientDispatch}
                TYPES={TYPES}
              >
                <FormButtons
                  setActiveStep={setActiveStep}
                  activeStep={activeStep}
                  isStepSkipped={isStepSkipped}
                  skipped={skipped}
                  setSkipped={setSkipped}
                  getStepContent={getStepContent}
                  cartState={cartState}
                  isComplete={isComplete}
                  setShowFormErrors={setShowFormErrors}
                  steps={steps}
                  order={state}
                  clientState={clientState}
                  autoConfirmOrder={autoConfirmOrder}
                  showFormErrorsMessage={showFormErrorsMessage}
                  setShowFormErrorsMessage={setShowFormErrorsMessage}
                  refsTotalObjetiveSticky={refsTotalObjetiveSticky}
                />
                <ModalConfirm activeStep={activeStep} />                
              </PendientOrderContextProvider>
            </ProductsContextProvider>
          </ClientContextProvider>
        )}
      </div>
      <div id="carousel"></div>
    </div>
  );
};

export default Form;
