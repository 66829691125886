import React from 'react'
import PropTypes from 'prop-types';

/* Components */
import Subtitle from '../../../../components/Subtitle'
import DeliveryFormTopBox from './DeliveryFormTopBox';
import Deliveryformdatabox from './DeliveryFormDataBox';
import { useConfigsContext } from '../../../../../../context/configsContextProvider';

const DeliveryFormDataContainer = ({
  setClientPostalCode,
  setDeliveryDay,
  setclientAdditionalData,
  setclientAddress,
  setclientFloor,
  setclientCity,
  setClientZone,
  clientAddress,
  clientCity,
  clientPostalCode,
  clientFloor,
  clientZone,
  zones, 
  postalCodeError,
  setPostalCodeError,
  showFormErrors,
  answerPostalCode,
  setAnswerPostalCode,
  deliveryDay,
  clientAdditionalData,
  clientStreetNumber,
  setClientStreetNumber
}) => {
    const {configs} = useConfigsContext(); 

   
    const handleAdditionalData = (e) => setclientAdditionalData(e.target.value);

    return (
      <div className="container delivery">
        <ul className="delivery-title">
          {
            configs["DELIVERY_MIN_AMOUNT"].active ? (
              <li>Envíos a todo el AMBA. Valor ${configs["DELIVERY_COST"]} o GRATIS! a partir de tu pedido de ${configs["DELIVERY_MIN_AMOUNT"].value}.</li>
            ) : (
              <li>Envíos a todo el AMBA. Valor ${configs["DELIVERY_COST"]}.</li>
            )
          }
          <li>El día de entrega recibís por anticipado el horario exacto de llegada a tu domicilio.</li>
        </ul>
        <DeliveryFormTopBox 
          setClientPostalCode={setClientPostalCode} 
          setDeliveryDay={setDeliveryDay}
          zones={zones}
          setPostalCodeError={setPostalCodeError}
          postalCodeError={postalCodeError}
          setAnswerPostalCode={setAnswerPostalCode}
          answerPostalCode={answerPostalCode}
          setClientZone={setClientZone}
          clientZone={clientZone}
          showFormErrors={showFormErrors}
          clientPostalCode={clientPostalCode}
          deliveryDay={deliveryDay}
        />
        <Deliveryformdatabox 
         clientAddress={clientAddress}
         clientCity={clientCity}
         clientFloor={clientFloor}
         clientPostalCode={clientPostalCode} 
         showFormErrors={showFormErrors} 
         setclientAddress={setclientAddress}
         setclientFloor={setclientFloor}
         setclientCity={setclientCity}
         answerPostalCode={answerPostalCode}
         clientStreetNumber={clientStreetNumber}
         setClientStreetNumber={setClientStreetNumber}
        />
        <div>
          <textarea
            className="form-control"
            name="additional-data"
            id=""
            cols="30"
            rows="10"
            placeholder="Indicaciones adicionales de entrega"
            disabled={!answerPostalCode}
            onChange={(e) => handleAdditionalData(e)}
          ></textarea>
        </div>
      </div>
    )
}

DeliveryFormDataContainer.propTypes = {
  setClientPostalCode:PropTypes.func,
  setDeliveryDay:PropTypes.func,
  setclientAdditionalData:PropTypes.func,
  setclientAddress:PropTypes.func,
  setclientFloor:PropTypes.func,
  setclientCity:PropTypes.func,
  setClientZone:PropTypes.func,
  clientAddress:PropTypes.string,
  clientCity:PropTypes.string,
  clientPostalCode:PropTypes.string,
  clientFloor:PropTypes.string,
  zones:PropTypes.array, 
  postalCodeError:PropTypes.bool,
  setPostalCodeError:PropTypes.func,
  showFormErrors:PropTypes.bool,
  answerPostalCode:PropTypes.bool,
  setAnswerPostalCode:PropTypes.func,
  deliveryDay:PropTypes.string,
}

export default DeliveryFormDataContainer;