import React, { createContext, useState, useCallback, useMemo, useContext, useEffect, useReducer } from "react";
import PropTypes from 'prop-types';
import pendientOrderServices from "../services/api_services/pendientOrderService";
import { useProductsContext } from "./productsContextProvider";
import { getProductsInPendientOrder, setPropOrderQuantityInProducts } from "../utils";
import { STEPS } from "../constants";

export const PendientOrderContext = createContext(); //Devuelve un objeto con la propiedad provider.

export function PendientOrderContextProvider({ 
  children, 
  CART_TYPES,
  cartDispatch, 
  setSelectedPoint,
  ORDER_TYPES,
  dispatch,
  setActiveStep,
  setModality,
  clientDispatch,
  TYPES,  
}) {
  const [ pendientOrder, setPendientOrder ] = useState(null);
  const [ pendientOrderId, setPendientOrderId ] = useState(null);
  const [ isCancel, setIsCancel ] = useState(false);
  const [ continuePendientOrder, setContinuePendientOrder ] = useState(false);
  const { allProducts, getAllProducts } = useProductsContext();
  const [ isNew, setIsNew ] = useState(false);
  

  useEffect(() => {
    if(pendientOrder && pendientOrderId === null) setPendientOrderId(pendientOrder.id)
  }, [pendientOrder])

  useEffect(() => {
    if(allProducts.length === 0){
      getAllProducts();
    }
  }, [])

  const continueWithPendientOrder = useCallback(() => {
    setContinuePendientOrder(true);
  })
 
  const getPendientOrder = useCallback(async (clientId) => {
    const fetchClientOrder = async () => {
      return await pendientOrderServices.getPendientOrder(clientId);
    }

    fetchClientOrder()
    .then(res => {
      setPendientOrder(res.data.data)
    })
    .catch(error => console.log(error)) 
  })

  const createPendientOrder = useCallback((clientId, order, periodId, selectedDay) => {
    const fetchPostOrder = async () => {
      let response = await pendientOrderServices.postPendientOrder(clientId, order, periodId, selectedDay);

      return response;
    }

    if(!isCancel) {
      fetchPostOrder()
      .then(resultado => {
        setPendientOrder(resultado.data);
        setIsNew(true);
      })
      .catch(error => console.log(error)); 
    }
  }, []);

  const updatePendientOrder = useCallback((pendientOrderId, order, periodId) => {
    const fetchUpdateOrder = async () => {
      let response = await pendientOrderServices.updatePendientOrder(pendientOrderId, order, periodId);

      return response;
    }

    if(!isCancel) {
      fetchUpdateOrder()
      .then(resultado => {
        setPendientOrder(resultado.data);
        setIsCancel(false);
      })
      .catch(error => console.log(error)) 
    }
  }, [setPendientOrder]);

  const deletePendientOrder = useCallback((pendientOrderId) => {
    const fetchDeleteOrder = async () => {
      let response = await pendientOrderServices.deletePendientOrder(pendientOrderId);

      return response;
    }
      fetchDeleteOrder()
      .then((_) => {
        setIsCancel(true);
        setPendientOrder(null);
        setPendientOrderId(null);
      })
      .catch(error => console.log(error)) 
  }, [setPendientOrder]);

  const setPendientOrderProductsInCart = useCallback((pendientOrder) => {
   if(pendientOrder){
    const pendientOrderProducts = getProductsInPendientOrder(pendientOrder.orden.products)
    const pendientOrderProductsCompleteObjects = setPropOrderQuantityInProducts(pendientOrderProducts, allProducts)
    
    pendientOrderProductsCompleteObjects.forEach(prod => {
      cartDispatch({ type: CART_TYPES.ADD_TO_CART_PENDIENT_ORDER, payload: prod });
    })
   }
  }, [pendientOrder])

  useEffect(() => {
    if(continuePendientOrder){

      setPendientOrderProductsInCart(pendientOrder);

      if(pendientOrder.orden.type === "PICKUP"){
        const point = {
          id: pendientOrder.orden.point?.slice(12),
          openDay: pendientOrder.orden.pointday
        }
        setSelectedPoint(point);
        setModality("retirement");
      }
  
      if(pendientOrder.orden.type === "DELIVERY"){
        clientDispatch({ type: TYPES.UPDATE_CLIENT_ADDRESS, payload: pendientOrder.orden.address });
        setModality("delivery");
      }
  
      if(pendientOrder.orden.products.length > 0) {
        setActiveStep(STEPS.PEDIDO /* 1 */);
      }
    }
  }, [continuePendientOrder])

  const value = useMemo(
    () => (
      {
        createPendientOrder,
        updatePendientOrder,
        pendientOrder,
        getPendientOrder,
        pendientOrderId,
        deletePendientOrder,
        isCancel,
        continueWithPendientOrder,
        setPendientOrderProductsInCart,
        continuePendientOrder, 
        isNew
      }),
      [
        createPendientOrder, 
        updatePendientOrder, 
        pendientOrder, 
        getPendientOrder, 
        pendientOrderId, 
        deletePendientOrder, 
        isCancel, 
        continueWithPendientOrder,
        setPendientOrderProductsInCart,
        continuePendientOrder,
        isNew
      ]
  );

  return <PendientOrderContext.Provider value={value}>{children}</PendientOrderContext.Provider>
}

PendientOrderContextProvider.propTypes = {
    children: PropTypes.array,
}

export const usePendientOrderContext = () => {
  return useContext(PendientOrderContext);
}