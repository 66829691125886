import React from 'react'

export default function PaymentItem({title, description, code, setMethod, methodActive}) {
  const isActive = methodActive === code
  return (
    <li className={`payment-item ${isActive ? "active" : null}`} onClick={() => setMethod(code)}>
      <span className="contain-circle">
        {isActive && <span className="circle"></span>}
      </span>

      <span className="container-text">
        <span className="payment-title">{title}</span> 
        <span>{"  " + description}</span>
      </span>
    </li>
  )
}
