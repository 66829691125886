import React from "react";
import SectionTitle from "../SectionTitle";
import ProductCard from "../ProductCard";

export default function ProductSectionContainer({
  sectionTitle,
  products,
  addToCart,
  delFromCart,
  order,
  dispatch,
  incrementReturnableQuantity,
  decrementReturnableQuantity,
  subtitle = null
}) {
  return (
    <div className="productsSectionContainer">
      <SectionTitle text={sectionTitle} />
      {subtitle}
      {products?.map((product) => (
            <ProductCard
              product={product}
              key={`${Math.random()}`}
              addToCart={addToCart}
              delFromCart={delFromCart}
              order={order}
              dispatch={dispatch}
              incrementReturnableQuantity={incrementReturnableQuantity}
              decrementReturnableQuantity={decrementReturnableQuantity}
            />
          ))}
    </div>
  );
}
