import connect from "./axios";

const clientsServices = {
  getOne: async (email) => {
    let response;
    await connect
      .get(`/clients?email=${email}`)
      .then((res) => {
        response = res.data;
      })
      .catch((err) => console.log(err));
    return response;
  },
  getById: async (id) => {
    let response;
    await connect
      .get(`/clients/${id}`)
      .then((res) => {
        response = res.data;
      })
      .catch((err) => console.log(err));
    return response;
  },
  getAll: async () => {
    let response = [];
    await connect
      .get("/clients.json")
      .then((res) => {
        response.push(res.data);
      })
      .catch((err) => console.log(err));
    return response;
  },
  newClient: async (data) => {
    return await connect.post("/clients", data);
  },
  updateClient: async (id, data) => {
    return await connect.put(`/clients/${id}`, data);
  },
};
export default clientsServices;
