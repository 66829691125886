import React, {
  createContext,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import PropTypes from "prop-types";
import pendientOrderServices from "../services/api_services/pendientOrderService";
import productsServices from "../services/api_services/productsService";
import { comparar, converterPricesForReturnables, filterActiveProducts, groupProducts } from "../utils";
import { MODALITY_CONSTANTS } from "../constants";

export const ProductsContext = createContext(); //Devuelve un objeto con la propiedad provider.

const productsMock = [
  {
    id: "1",
    name: "Cajoncito",
    nameSheet: "",
    icon: "",
    brief: null,
    description: null,
    unit: "Kgs",
    quantity: "5",
    category: "Combos",
    children: [
      "Manzana",
      "Naranja",
      "Choclo",
      "Tomate",
      "Frutilla",
      "Melon",
      "Sandia",
    ],
    parent: "/api/products/2",
    price: 213,
    image: null,
    sale: false,
    active: true,
    orden: null,
    delivery: true,
    point: true,
    start: "2022-10-01T00:00:00+02:00",
    end: null,
    orderQuantity: 1,
    imageUrl: "https://elclick.com.ar/uploads/Bolsones%208kg-productslider",
  },
  {
    id: "2",
    name: "Bolson mixto",
    nameSheet: "",
    icon: "",
    brief: null,
    description: null,
    unit: "Kg",
    quantity: "8",
    category: "Combos",
    children: [
      "Manzana",
      "Naranja",
      "Choclo",
      "Tomate",
      "Frutilla",
      "Melon",
      "Sandia",
      "Tomate",
      "Frutilla",
      "Melon",
      "Sandia",
    ],
    parent: "/api/products/2",
    price: 213,
    image: null,
    sale: false,
    active: true,
    orden: null,
    delivery: true,
    point: true,
    start: "2022-10-01T00:00:00+02:00",
    end: null,
    orderQuantity: 1,

    imageUrl: "https://elclick.com.ar/uploads/Bolsones%208kg-productslider",
  },
  {
    id: "3",
    name: "Zanahorias",
    nameSheet: "",
    icon: "",
    brief: null,
    description: null,
    unit: "gr",
    quantity: "500",
    category: "Suelto",
    children: [],
    parent: "/api/products/2",
    price: 213,
    image: null,
    sale: false,
    active: true,
    orden: null,
    delivery: true,
    point: true,
    start: "2022-10-01T00:00:00+02:00",
    end: null,
    orderQuantity: 1,

    imageUrl: "https://elclick.com.ar/uploads/Bolsones%208kg-productslider",
  },
  {
    id: "4",
    name: "Jengibre",
    nameSheet: "",
    icon: "",
    brief: null,
    description: null,
    unit: "Gs",
    quantity: "200",
    category: "Almacen",
    children: [],
    parent: "/api/products/2",
    price: 213,
    image: null,
    sale: false,
    active: true,
    orden: null,
    delivery: true,
    point: true,
    start: "2022-10-01T00:00:00+02:00",
    end: null,
    orderQuantity: 1,

    imageUrl: "https://elclick.com.ar/uploads/Bolsones%208kg-productslider",
  },
  {
    id: "5",
    name: "Naranjas",
    nameSheet: "",
    icon: "",
    brief: null,
    description: null,
    unit: "Kg",
    quantity: "2",
    category: "Suelto",
    children: [],
    parent: "/api/products/2",
    price: 213,
    image: null,
    sale: false,
    active: true,
    orden: null,
    delivery: true,
    point: true,
    start: "2022-10-01T00:00:00+02:00",
    end: null,
    orderQuantity: 1,

    imageUrl: "https://elclick.com.ar/uploads/Bolsones%208kg-productslider",
  },
  {
    id: "6",
    name: "Oregano Fresco",
    nameSheet: "",
    icon: "",
    brief: null,
    description: null,
    unit: "Grs",
    quantity: "200",
    category: "Otros",
    children: [],
    parent: "/api/products/2",
    price: 213,
    image: null,
    sale: false,
    active: true,
    orden: null,
    delivery: true,
    point: true,
    start: "2022-10-01T00:00:00+02:00",
    end: null,
    orderQuantity: 1,

    imageUrl: "https://elclick.com.ar/uploads/Bolsones%208kg-productslider",
  },
  {
    id: "7",
    name: "Yerba",
    nameSheet: "",
    icon: "",
    brief: null,
    description: null,
    unit: "Grs",
    quantity: "500",
    category: "Almacen",
    children: [],
    parent: "/api/products/2",
    price: 213,
    image: null,
    sale: false,
    active: true,
    orden: null,
    delivery: true,
    point: true,
    start: "2022-10-01T00:00:00+02:00",
    end: null,
    orderQuantity: 1,

    imageUrl: "https://elclick.com.ar/uploads/Bolsones%208kg-productslider",
  },
  {
    id: "8",
    name: "Tierra abonada",
    nameSheet: "",
    icon: "",
    brief: null,
    description: null,
    unit: "Kgs",
    quantity: "5",
    category: "Otros",
    children: [],
    parent: "/api/products/2",
    price: 213,
    image: null,
    sale: false,
    active: true,
    orden: null,
    delivery: true,
    point: true,
    start: "2022-10-01T00:00:00+02:00",
    end: null,
    orderQuantity: 1,

    imageUrl: "https://elclick.com.ar/uploads/Bolsones%208kg-productslider",
  },
];

export function ProductsContextProvider({ children, cartState, modality }) {
  const [existFetchProducts, setExistFetchProducts] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [productsByCategory, setProductsByCategory] = useState([]);
  const [productsBag, setProductsBag] = useState([]);
  const orderModality =
  modality === MODALITY_CONSTANTS.DELIVERY
  ? MODALITY_CONSTANTS.DELIVERY
  : "point";
  const [lastModality, setLastModality] = useState(MODALITY_CONSTANTS.RETIREMENT);

  const getAllProducts = useCallback(() => {
      const fetchProducts = async () => await productsServices.getByBag();
      fetchProducts().then((res) => {
        let { data } = res.data;

        data = converterPricesForReturnables(data);

        const filteredProducts = filterActiveProducts(
          data,
          orderModality
          );

        setAllProducts(filteredProducts);
        const groupedProducts = groupProducts(filteredProducts);
        setProductsByCategory(groupedProducts);
        setExistFetchProducts(true)
        setLastModality(modality)
      });
  });

  const updateProducts = useCallback(() => {
    if (cartState.cart.length > 0) {
      const updatedProducts = [];
      allProducts.forEach((prod) => {
        cartState.cart.forEach((prodInCart) => {
          if (prod.id === prodInCart.id) {
            prod.orderQuantity = prodInCart.orderQuantity;
            prod.returnableQuantity = prodInCart.returnableQuantity;
          }
        });
        updatedProducts.push(prod);
      });
      setAllProducts(updatedProducts);
    }
  });

  const getProductsByActiveBag = useCallback(() => {
    const fetchProductsByBag = async () => await productsServices.getByBag();
    fetchProductsByBag().then(({ data }) => {
      const { data: products } = data;
      setProductsBag(products);
    });
  });

  const value = useMemo(
    () => ({
      getAllProducts,
      allProducts,
      updateProducts,
      productsByCategory,
      productsBag,
      getProductsByActiveBag,
      existFetchProducts,
      lastModality
    }),
    [
      getAllProducts,
      allProducts,
      updateProducts,
      productsByCategory,
      getProductsByActiveBag,
      existFetchProducts,
      lastModality
    ]
  );

  return (
    <ProductsContext.Provider value={value}>
      {children}
    </ProductsContext.Provider>
  );
}

ProductsContextProvider.propTypes = {
  children: PropTypes.object,
};

export const useProductsContext = () => {
  return useContext(ProductsContext);
};
