import React, { 
  createContext, 
  useState, 
  useMemo, 
  useContext, 
} from "react";
import PropTypes from 'prop-types';

export const ClientContext = createContext(); //Devuelve un objeto con la propiedad provider.

export function ClientContextProvider({ children }) {
  const [isNew, setIsNew] = useState(false);

  const value = useMemo(
    () => (
      {
        isNew,
        setIsNew,
      }),
      [isNew]
    );

  return <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
}

ClientContextProvider.propTypes = {
    children: PropTypes.object,
}

export const useClientContext = () => {
  return useContext(ClientContext);
}