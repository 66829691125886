import axios from "axios";

/**
 * Obtiene el período actual desde el servidor.
 * @returns {Promise<Object>} Objeto con el período actual.
 * @throws {Error} Si ocurre un error al hacer la solicitud.
 */
const periodServices = {
  getCurrentPeriod: async () => {
    try {
      const { data } = await axios.get("/week_active");
      return data;
    } catch (error) {
      console.error("Error al obtener el período actual:", error);
      throw error; // Lanzar la excepción para que la capa superior pueda manejarla
    }
  },
};

export default periodServices;
