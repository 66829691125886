import React from 'react';
import PropTypes from 'prop-types';

/* Components */
import FormInput from "./FormInput";
import Subtitle from '../../../../components/Subtitle'
const Deliveryformdatabox = ({
    clientAddress,
    clientCity,
    clientFloor,
    clientPostalCode, 
    showFormErrors, 
    setclientAddress,
    setclientCity,
    setclientFloor,
    answerPostalCode,
    clientStreetNumber,
    setClientStreetNumber
}) => {

    const handleCity = (e) => setclientCity(e.target.value);
    const handleAddress = (e) => setclientAddress(e.target.value);
    const handleFloor = (e) => setclientFloor(e.target.value);
    const handleStreetNumber = (e) => setClientStreetNumber(e.target.value);

    return (
        <div className="delivery-data row">
            <Subtitle textContent="Datos de envío" className="delivery-subtitle"/>
            <FormInput
                type="text"
                className="form-control"
                name="city"
                placeholder="Localidad/Barrio*"
                value={clientCity}
                disabled={!answerPostalCode}
                eventHandler={(e) => handleCity(e)}
                error={showFormErrors && clientCity == "" && clientPostalCode ? true : false}
                errorMsg={'Campo requerido'}
            />
            <FormInput
                type="text"
                className="form-control"
                name="street"
                placeholder="Calle*"
                value={clientAddress}
                disabled={!answerPostalCode}
                eventHandler={(e) => handleAddress(e)}
                error={showFormErrors && clientAddress == "" && clientPostalCode ? true : false}
                errorMsg={'Campo requerido'}
            />
            <FormInput
                type="text"
                className="form-control"
                name="streetNumber"
                placeholder="Número*"
                value={clientStreetNumber}
                disabled={!answerPostalCode}
                eventHandler={(e) => handleStreetNumber(e)}
                error={showFormErrors && clientStreetNumber == "" && clientPostalCode ? true : false}
                errorMsg={'Campo requerido'}
            />
            <FormInput
                type="text"
                className="form-control"
                name="floor"
                placeholder="Departamento/Piso*"
                value={clientFloor}
                disabled={!answerPostalCode}
                eventHandler={(e) => handleFloor(e)}
            />
      </div>
    );
};


Deliveryformdatabox.propTypes = {
    clientAddress:PropTypes.string,
    clientCity:PropTypes.string,
    clientFloor:PropTypes.string,
    clientPostalCode:PropTypes.string, 
    showFormErrors:PropTypes.bool, 
    answerPostalCode: PropTypes.bool,
    clientStreetNumber: PropTypes.string,
    setClientStreetNumber: PropTypes.func
};


export default Deliveryformdatabox;
