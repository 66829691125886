import React, { useEffect, useState } from "react";
import OrderBody from "./OrderBody";
import OrderTabs from "./OrderTabs";
import "./Order.css";
import { useProductsContext } from "../../../../../context/productsContextProvider";
import { TAB_CATEGORIES_LIST } from "../../../../../constants";


export default function OrderWrapper({
  addToCart,
  delFromCart,
  order,
  dispatch,
  incrementReturnableQuantity,
  decrementReturnableQuantity,
}) {
  const [tabActive, setTabActive] = useState(0);
  const { productsByCategory } = useProductsContext();
  const [categoriesWithProducts, setCategoriesWithProducts] = useState([]);

  
  useEffect(() => {  
    const categoriesFilter = TAB_CATEGORIES_LIST.filter(
      ({ key }) => productsByCategory[key]?.length || (key === "todo")
    );
    setCategoriesWithProducts(categoriesFilter);
  }, [productsByCategory]);

  return (
    <div className="orderWrapper">
      <OrderTabs
        value={tabActive}
        setValue={setTabActive}
        items={categoriesWithProducts}
      />
      <OrderBody
        selectedTab={tabActive}
        addToCart={addToCart}
        delFromCart={delFromCart}
        order={order}
        dispatch={dispatch}
        incrementReturnableQuantity={incrementReturnableQuantity}
        decrementReturnableQuantity={decrementReturnableQuantity}
      />
    </div>
  );
}
