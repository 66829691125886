import React, { useEffect, useState } from 'react';
import "./index.css";
import PaymentItem from './PaymentItem'
import paymentMethodServices from "../../../../../services/api_services/paymentMethodService";

export default function ContainerPaymentMethods({ setTypePayment, typePayment }) {
    const handleTypePayment = (code) => setTypePayment(code);

    const [methodPayments, setMethodPayments] = useState(null);

    useEffect(() => {
        paymentMethodServices.getAll().then(({data}) => {
            const filteredMethods = data.filter((method) => method.enabled === true);
            setMethodPayments(filteredMethods.map(({ name, description, method }) => ({
                title: name,
                description: description,
                code: method,
            })));
        });
    }, []);

    return (
        <ol className="container-payment-methods">
            {methodPayments?.map((method) => (  // Check for filtered methods before map
                <PaymentItem
                    key={method.code}
                    methodActive={typePayment}
                    setMethod={handleTypePayment}
                    {...method}
                />
            ))}
        </ol>
    );
}
