import React from "react";
import PropTypes from "prop-types";

export const ModalityDisabled = ({ label, info }) => {
  return (
    <div className={`modality-box disabled-box`}>
      <p>{info}</p>
    </div>
  );
};

ModalityDisabled.propTypes = {
    info: PropTypes.string.isRequired,
};
