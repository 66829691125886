import { TYPES } from "../actions/clientActions";

export const clientInitialState = {
  id: null,
  name: "",
  email: "",
  areaCode: "",
  phone: "",
  street: "",
  streetNumber: "",
  postal_code: "",
  city: "",
  floor: "",
  additionalData: "",
  day: "",
  modalityAddressSrc: "" // delivery | retirement
};

export function clientReducer(state, action) {
  switch (action.type) {
    case TYPES.SEARCH_CLIENT:
      {
        let clientFound = action.payload;
        return clientFound.length !== 0
          ? {
              ...state,
              id: clientFound[0].id,
              name: clientFound[0].name,
              email: clientFound[0].email,
              phone: clientFound[0].phone,
              areaCode: clientFound[0].areaCode ?? "",
              street: clientFound[0].address.street ?? "",
              streetNumber: clientFound[0].address.streetNumber ?? "",
              postal_code: clientFound[0].address.pc ?? "",
              city: clientFound[0].address.city ?? "",
              floor: clientFound[0].address.floor ?? "",
              additionalData: clientFound[0].address.additionalData ?? "",
              day: clientFound[0].address.day,
              modalityAddressSrc: clientFound[0].address.modalityAddressSrc,
            }
          : clientInitialState;
      }
      break;
    case TYPES.CREATE_CLIENT: {
      let newClient = action.payload;
      return newClient.length !== 0
        ? {
            ...state,
            id: newClient.id,
            name: newClient.name,
            email: newClient.email,
            phone: newClient.phone,
            areaCode: newClient.areaCode,
          }
        : clientInitialState;
    }
    case TYPES.UPDATE_CLIENT: {
      let updatedClient = action.payload;
      return updatedClient.length !== 0
        ? {
            ...state,
            id: updatedClient.id,
            name: updatedClient.name,
            email: updatedClient.email,
            phone: updatedClient.phone,
            areaCode: updatedClient.areaCode,
          }
        : state;
    }
    case TYPES.SET_CLIENT_ADDRESS: {
      let clientAddressData = action.payload;
      return clientAddressData.length !== 0
        ? {
            ...state,
            address: clientAddressData.address,
            postal_code: clientAddressData.pc,
            city: clientAddressData.city,
            flat: clientAddressData.floor,
            additionalData: clientAddressData.additionalData,
            day: clientAddressData.day,
            modalityAddressSrc: clientAddressData.modalityAddressSrc,
          }
        : state;
    }
    case TYPES.UPDATE_CLIENT_ADDRESS: {
      let clientAddressData = action.payload;
      return clientAddressData.length !== 0
        ? {
            ...state,
            street: clientAddressData.street,
            streetNumber: clientAddressData.streetNumber,
            postal_code: clientAddressData.pc,
            city: clientAddressData.city,
            floor: clientAddressData.floor,
            additionalData: clientAddressData.additionalData,
            day: clientAddressData.day,
            modalityAddressSrc: clientAddressData.modalityAddressSrc,
          }
        : state;
    }
    case TYPES.CLEAR_CLIENT: {
      return clientInitialState
    }
    default:
      return state;
  }
}
