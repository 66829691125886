import axios from "axios";
import { PENDIENT_ORDERS } from "../local_services/pendientOrders";
import connect from "./axios";

const pendientOrderServices = {
  postPendientOrder: async (clientId, order, bagId, selectedDay) => {
    const data = {
      client: `/api/clients/${clientId}`,
      orden: order,
      bagid: `${bagId}`,
      day: selectedDay,
    }

    return await connect.post("/intentions", data)
    .then((res) => res)
    .catch((err) => console.log(err))
  },
  updatePendientOrder: async (pendientOrderId, order, bagId) => {
    const data = {
      orden: order,
      bagid: bagId
     }
    return await connect.put(`/intentions/${pendientOrderId}`, data)
    .then((res) => res)
    .catch((err) => console.log(err))
  },
  getPendientOrder: async (clientId) => {
    return await axios.get(`/intention/${clientId}`)
    .then((res) => res)
    .catch((err) => console.log(err))
  },
  deletePendientOrder: async (pendientOrderId) => {
    return await connect.delete(`/intentions/${pendientOrderId}`)
    .then((res) => res)
    .catch((err) => console.log(err))
  },
};
export default pendientOrderServices;
