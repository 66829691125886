import connect from "./axios";

const pointsServices = {
  getOneZone: async (id) => {
    let response;
    await connect
      .get(`/zone/${id}`)
      .then((res) => {
        response = res.data;
      })
      .catch((err) => console.log(err));
    return response;
  },
  getAllZones: async () => {
    let response = [];
    await connect
      .get("/zones.json")
      .then((res) => {
        response.push(res.data);
      })
      .catch((err) => console.log(err));
    return response;
  },
  getOnePoint: async (id) => await connect.get(`/points/${id}`),
  getAllPoints: async () => {
    return await connect.get("/points.json");
  },
};
export default pointsServices;
