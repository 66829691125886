import React from 'react'
import PropTypes from 'prop-types';

const Subtitle = ({
    className = "",
    textContent = ""
}) => {
    return (
      <h4 className={className}>{textContent}</h4>
    )
}

Subtitle.propTypes = {
    className: PropTypes.string,
    textContent: PropTypes.string.isRequired
}

export default Subtitle