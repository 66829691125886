import React, { useEffect, useState } from "react";
import FilterSelect from "./FilterSelect";
import { useConfigsContext } from "../../../../../../../context/configsContextProvider";

const FiltersBox = ({
  setFilteredPoints,
  setIsFilter,
  isFilter,
  points,
  zones,
  selectedZone,
  setSelectedZone,
  selectedDay,
  setSelectedDay,
}) => {

  const [days, setDays] = useState([]);

/*   useEffect(() => {
    let pointDays = [];
    points.forEach((point) => {
      point.days.forEach((day) => {
        if (!pointDays.includes(day)) {
          pointDays.push(day);
        }
      });
    }); 

    pointDays.sort(function (a, b) {
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
    setDays(pointDays);
  }, [points]); */
  const {configs} = useConfigsContext();

  useEffect(() => {
    const days = Object.entries(configs).sort().filter(([key]) => /^DAY_[A-Z]/.test(key)).map(([_, value]) => value)
    setDays(days)
  }, []);

  useEffect(() => {
    if (selectedDay !== "" || selectedZone !== "") {
      setIsFilter(true);
    }
    switch (true) {

      case (selectedDay !== "todos" && selectedZone === "") ||
        (selectedDay !== "todos" &&
          selectedDay !== "" &&
          selectedZone == "todas"):
        let filterPoints = points.filter((point) =>
          point.days.includes(selectedDay)
        );
        setFilteredPoints(filterPoints);
        break;

      case selectedDay !== "todos" &&
        selectedDay !== "" &&
        selectedZone !== "todas" &&
        selectedZone !== "":
        filterPoints = points.filter(
          (point) =>
            point.days.includes(selectedDay) &&
            point.zone.slice(-1) == selectedZone
        );
        setFilteredPoints(filterPoints);
        break;
      case selectedDay == "todos" &&
        selectedZone !== "todas" &&
        selectedZone !== "":
        filterPoints = points.filter((point) =>
          point.zone.includes(selectedDay)
        );
        setFilteredPoints(filterPoints);
      case selectedDay == "" && selectedZone !== "todas" && selectedZone !== "":
        filterPoints = points.filter(
          (point) => point.zone.slice(-1) == selectedZone
        );
        setFilteredPoints(filterPoints);
        break;
      default:
        setIsFilter(false);
        break;
    }
  }, [selectedZone, selectedDay, isFilter]);

  return (
    <div className="container" style={{ padding: 0 }}>
      <div className="row">
        <FilterSelect
          label="Elegí una zona"
          setState={setSelectedZone}
          array={zones}
          menuItemValue="todas"
          menuItemText="Todas"
          selectedZone={selectedZone}
        />
        <FilterSelect
          label="Elegí un día"
          setState={setSelectedDay}
          array={days}
          menuItemValue="todos"
          menuItemText="Todos"
          isDay={true}
          selectedDay={selectedDay}
        />
      </div>
    </div>
  );
};

export default FiltersBox;
