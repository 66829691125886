import React from "react";
import PropTypes from "prop-types";

/* Components */
import PointItem from "../PointItem";
import {
  RadioGroup,
  FormControl,
  Box,
  CircularProgress,
} from "@material-ui/core";

const PointsContainer = ({
  isFilter,
  filteredPoints,
  points,
  loading,
  handlePoint,
  pointInOrder
}) => {
  return (
    <FormControl component="fieldset" className="radio-container">
      <RadioGroup name="radio-buttons-group">
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : isFilter ? (
          filteredPoints.map((point) => (
            <PointItem key={point.id} point={point} handlePoint={handlePoint} pointInOrder={pointInOrder} />
          ))
        ) : (
          points.map((point) => (
            <PointItem key={point.id} point={point} handlePoint={handlePoint} pointInOrder={pointInOrder} />
          ))
        )}
      </RadioGroup>
    </FormControl>
  );
};

PointsContainer.propTypes = {
  isFilter: PropTypes.bool,
  filteredPoints: PropTypes.array,
  points: PropTypes.array,
  loading: PropTypes.bool,
  handlePoint: PropTypes.func,
};

export default PointsContainer;
