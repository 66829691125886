import React from 'react';
import PropTypes from 'prop-types';

/* Components */
import DeliveryPostalCodeContainer from './DeliveryPostalCodeContainer';
import DeliverySelectDaysContainer from './DeliverySelectDaysContainer';


const DeliveryFormTopBox = ({
  setClientPostalCode,
  setDeliveryDay, 
  zones,
  setPostalCodeError,
  postalCodeError,
  setAnswerPostalCode,
  setClientZone,
  clientZone, 
  showFormErrors,
  clientPostalCode,
  deliveryDay,
  answerPostalCode
}) => {

    const fetchPostalCode = () => {
      if (zones.length > 0) {
        let zone = zones.find((zone) => zone.postalCode.includes(clientPostalCode));
        if (zone !== undefined) {
          setClientZone(zone);
          setPostalCodeError(false);
          setAnswerPostalCode(true);
        } else {
          setPostalCodeError(true);
          setAnswerPostalCode(false);
        }
      }
    };
  
    return (
        <div className="delivery-block1 row">
          <DeliverySelectDaysContainer 
             answerPostalCode={answerPostalCode}
             showFormErrors={showFormErrors}
             deliveryDay={deliveryDay}
             setDeliveryDay={setDeliveryDay}
             clientZone={clientZone}
          />
          <DeliveryPostalCodeContainer 
            clientPostalCode={clientPostalCode}
            fetchPostalCode={fetchPostalCode}
            setClientPostalCode={setClientPostalCode}
            postalCodeError={postalCodeError}
            showFormErrors={showFormErrors}
          />
        </div>
    );
};


DeliveryFormTopBox.propTypes = {

};


export default DeliveryFormTopBox;
