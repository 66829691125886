import React from "react";
import { ConfigsContextProvider } from "../context/configsContextProvider";
import { PendientOrderContextProvider } from "../context/pendientOrderContextProvider";

/* COMPONENTS */

import Form from "./Form/Form";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { PATH_STEPS } from "../constants";

const App = () => {
  return (
        <ConfigsContextProvider>
    <BrowserRouter>
      <Switch>
        <Route
          path={[
            "/",
            PATH_STEPS.DATOS_PUNTO_CLICK,
            PATH_STEPS.DATOS_DELIVERY,
            PATH_STEPS.PEDIDO_PUNTO_CLICK,
            PATH_STEPS.PEDIDO_DELIVERY,
            PATH_STEPS.MODALIDAD_PUNTO_CLICK,
            PATH_STEPS.MODALIDAD_DELIVERY,
            PATH_STEPS.CONFIRMAR_PUNTO_CLICK,
            PATH_STEPS.CONFIRMAR_DELIVERY,
          ]}
        >
            <Form />
        </Route>
      </Switch>
    </BrowserRouter>
          </ConfigsContextProvider>
  );
};

export default App;
