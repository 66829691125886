import connect from "./axios";

const configServices = {
  getConfig: async () => {
    let response = [];
    await connect
      .get(`/configs.json`)
      .then((res) => {
        response = res.data;
      })
      .catch((err) => console.log(err));
    return response;
  },
};
export default configServices;
