import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

/* Components */
import { TextField } from "@material-ui/core";

const DeliveryPostalCodeContainer = ({
  clientPostalCode,
  fetchPostalCode,
  setClientPostalCode,
  postalCodeError,
  showFormErrors,
}) => {
  const handlePostalCode = (e) => setClientPostalCode(e.target.value);
  const [msgError, setMsgError] = useState(null);

  useEffect(() => {
    if (!clientPostalCode && showFormErrors) {
      setMsgError("Campo requerido");
    }else if (!/^\d{4,6}$/.test(clientPostalCode)) {
      setMsgError("Ingresa un código postal válido");
    } else if (postalCodeError) {
      setMsgError("No realizamos entregas en tu zona");
    }  else {
      setMsgError(null);
    }
  },[clientPostalCode, postalCodeError]);

  return (
    <div className="col-md-6 delivery-postalCode_container">
      <div className="delivery-postalCode">
        <TextField
          type="text"
          name="postal_code"
          label="Código postal*"
          value={clientPostalCode}
          id="standard-name"
          variant="standard"
          onBlur={fetchPostalCode}
          onChange={(e) => handlePostalCode(e)}
        />
        <span className="code-info">
          <a
            href="https://www.correoargentino.com.ar/formularios/cpa"
            target="_blank"
            rel="noreferrer"
          >
            No sé mi código
          </a>
        </span>
      </div>
      
      <small className="text-danger error-pc">
        {msgError}
      </small>
    </div>
  );
};

DeliveryPostalCodeContainer.propTypes = {
  clientPostalCode: PropTypes.string,
  fetchPostalCode: PropTypes.func,
  setClientPostalCode: PropTypes.func,
  postalCodeError: PropTypes.bool,
  showFormErrors: PropTypes.bool,
};

export default DeliveryPostalCodeContainer;
