import axios from 'axios'

const paymentMethodServices = {
  getAll: async () => {
    let response = [];
    await axios
        .get(`/payment-methods`)
        .then((res) => {
          response = res.data;
        })
        .catch((err) => console.log(err));
    return response;
  },
};
export default paymentMethodServices;