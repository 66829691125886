import React from "react";
import PropTypes from "prop-types";

/* Components */
import { Radio, FormControlLabel } from "@material-ui/core";

const ModalityItem = ({
  modality,
  setModality,
  value = "",
  label = "",
  info = "",
  disabled = false,
}) => {
  return (
    <label htmlFor={value}>
      <div className={`modality-box ${modality === value ? "active-box" : ""} ${disabled && "disabled-box"}`}>
        <FormControlLabel
          disabled={disabled}
          checked={modality === value}
          value={value}
          control={<Radio id={value} />}
          label={label}
          className={`label ${modality === value ? "active-box-label" : ""} ${disabled && "disabled-label"}`}
          onClick={(e) => {
              !disabled && setModality(e.target.value)
            }}
        />
        <span className="modality-info">{info}</span>
      </div>
    </label>
  );
};

ModalityItem.propTypes = {
  modality: PropTypes.string,
  setModality: PropTypes.func,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
};

export default ModalityItem;
