import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import { usePendientOrderContext } from '../../../../context/pendientOrderContextProvider';
import './ModalConfirm.css';
import modalImage from "../../../../../img/modal-image.jpg";
import { STEPS } from '../../../../constants';

const ModalConfirm = ({activeStep}) => {
    const {pendientOrderId, deletePendientOrder, continueWithPendientOrder, continuePendientOrder, isNew} = usePendientOrderContext();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if(activeStep === STEPS.PEDIDO /* 1 */ && pendientOrderId && !continuePendientOrder && !isNew){
            setIsOpen(true)
        }
    }, [activeStep])
    
    const handleClose = () => setIsOpen(false)
    const handleCancelOrderPendient = () => {
        deletePendientOrder(pendientOrderId);
        handleClose();
    }

    const handleContinuePendientOrder = () => {
        continueWithPendientOrder();
        handleClose();
    }
    
    return (
        <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal__container"
        >
        <Box className="modal__Body">
            <i className="fa-solid fa-xmark" onClick={handleClose}></i>
            <Box className="modal__Body-image">
                <img src={modalImage} alt="Logo Click" />
            </Box>
            <p className="modal__Body-question">¿Querés continuar con tu compra?</p>
            <p className="modal__Body-paragraph">
                Hemos registrado que tenés 1 pedido sin terminar.
            </p>
            <Box className="modal__Body-buttonsContainer">
            <button
                className="modal__button new"
                onClick={handleCancelOrderPendient}
            >
                COMENZAR DE NUEVO
            </button>
            <button
                className="modal__button next"
                onClick={handleContinuePendientOrder}
            >
                RETOMAR PEDIDO
            </button>
            </Box>
        </Box>
        </Modal>
    );
};


ModalConfirm.propTypes = {
    activeStep: PropTypes.number.isRequired
};


export default ModalConfirm;
