import React, { useEffect } from "react";
import ProductSectionContainer from "./components/ProductSectionContainer";
import { useProductsContext } from "../../../../../../context/productsContextProvider";
import { TAB_CATEGORIES_LIST } from "../../../../../../constants";
import { useConfigsContext } from "../../../../../../context/configsContextProvider";
import Subtitle from "../../../../components/Subtitle";

function getProductsByCategory(products, categoryName) {
  return products[categoryName];
}

const TABS = {
  Todo: 0,
  Combos: 1,
  Suelto: 2,
  Almacen: 3,
  Otros: 4,
  Retornables: 5,
};

export default function OrderBody({
  selectedTab,
  addToCart,
  delFromCart,
  order,
  dispatch,
  incrementReturnableQuantity,
  decrementReturnableQuantity,
}) {
  const { productsByCategory } = useProductsContext();
  const { getConfigsData, configs } = useConfigsContext();

  useEffect(() => {
    getConfigsData();
  }, []);

  function getProductsList() {
    switch (selectedTab) {
      case TABS["Todo"]:
        return (
          <>
            {Object.entries(productsByCategory)?.map(([category, products]) =>
              !products.length || category === "retornables" ? null : (
                <ProductSectionContainer
                  products={products}
                  sectionTitle={
                    TAB_CATEGORIES_LIST.find(({ key }) => key === category)
                      ?.value
                  }
                  key={category}
                  addToCart={addToCart}
                  delFromCart={delFromCart}
                  order={order}
                  dispatch={dispatch}
                  incrementReturnableQuantity={incrementReturnableQuantity}
                  decrementReturnableQuantity={decrementReturnableQuantity}
                />
              )
            )}
          </>
        );
      case TABS["Combos"]:
        return (
          <>
            <ProductSectionContainer
              products={getProductsByCategory(
                productsByCategory,
                "Combos".toLowerCase()
              )}
              sectionTitle="Combos"
              key="Combos"
              addToCart={addToCart}
              delFromCart={delFromCart}
              order={order}
              dispatch={dispatch}
              incrementReturnableQuantity={incrementReturnableQuantity}
              decrementReturnableQuantity={decrementReturnableQuantity}
            />
          </>
        );
      case TABS["Suelto"]:
        return (
          <>
            <ProductSectionContainer
              products={getProductsByCategory(
                productsByCategory,
                "Suelto".toLowerCase()
              )}
              sectionTitle="Variedades sueltas"
              key="Suelto"
              addToCart={addToCart}
              delFromCart={delFromCart}
              order={order}
              dispatch={dispatch}
              incrementReturnableQuantity={incrementReturnableQuantity}
              decrementReturnableQuantity={decrementReturnableQuantity}
            />
          </>
        );
      case TABS["Almacen"]:
        return (
          <>
            <ProductSectionContainer
              products={getProductsByCategory(
                productsByCategory,
                "Almacen".toLowerCase()
              )}
              sectionTitle="Almacén"
              key="Almacen"
              addToCart={addToCart}
              delFromCart={delFromCart}
              order={order}
              dispatch={dispatch}
              incrementReturnableQuantity={incrementReturnableQuantity}
              decrementReturnableQuantity={decrementReturnableQuantity}
            />
          </>
        );
      case TABS["Otros"]:
        return (
          <>
            <ProductSectionContainer
              products={getProductsByCategory(
                productsByCategory,
                "Otros".toLowerCase()
              )}
              sectionTitle="Otros"
              key="Otros"
              addToCart={addToCart}
              delFromCart={delFromCart}
              order={order}
              dispatch={dispatch}
              incrementReturnableQuantity={incrementReturnableQuantity}
              decrementReturnableQuantity={decrementReturnableQuantity}
            />
          </>
        );
      case TABS["Retornables"]:
        return (
          <>
            <ProductSectionContainer
              products={getProductsByCategory(
                productsByCategory,
                "Retornables".toLowerCase()
              )}
              sectionTitle=""
              key="retornables"
              addToCart={addToCart}
              delFromCart={delFromCart}
              order={order}
              dispatch={dispatch}
              incrementReturnableQuantity={incrementReturnableQuantity}
              decrementReturnableQuantity={decrementReturnableQuantity}
              subtitle={
                configs.ORDER_SUBTITLE_RETURNABLES.active ? (
                  <>
                  <Subtitle
                    textContent={"Retornables"}
                    className="form-subtitle_order"
                    />
                    <p className="tip-paragraph">
                      {configs.ORDER_SUBTITLE_RETURNABLES.value}
                    </p>
                    </>
                ) : null
              }
            />
          </>
        );
      default:
        return <>cargando...</>;
    }
  }

  return (
    <div className="orderBodyContainer">
      {getProductsList()}
      {selectedTab === TABS["Retornables"] ? null :
        <div className="mt-2">
        <ProductSectionContainer
          products={getProductsByCategory(
            productsByCategory,
            "Retornables".toLowerCase()
          )}
          sectionTitle=""
          key="retornables"
          addToCart={addToCart}
          delFromCart={delFromCart}
          order={order}
          dispatch={dispatch}
          incrementReturnableQuantity={incrementReturnableQuantity}
          decrementReturnableQuantity={decrementReturnableQuantity}
          subtitle={
            configs.ORDER_SUBTITLE_RETURNABLES.active ? (
              <>
              <Subtitle
                textContent={"Retornables"}
                className="form-subtitle_order"
                />
                <p className="tip-paragraph">
                  {configs.ORDER_SUBTITLE_RETURNABLES.value}
                </p>
                </>
            ) : null
          }
        />
      </div>
    }
    </div>
  );
}
