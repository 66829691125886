import React, { useEffect } from "react";
import PropTypes from "prop-types";

/* Components */
import FormInput from "./FormInput";

const FormInputContainer = ({
  clientEmail,
  clientName,
  clientPhone,
  clientAreaCode,
  completePhoneError,
  setInputNameError,
  setInputEmailError,
  setInputPhoneError,
  setInputAreaCodeError,
  setCompletePhoneError,
  inputEmailError,
  inputNameError,
  inputPhoneError,
  inputAreaCodeError,
  setClientEmail,
  setClientName,
  setClientPhone,
  setClientAreaCode,
  clientDispatch,
  clientsService,
  TYPES,
  id,
  phone,
  client,
  areaCode
}) => {
  /*   const handleDataUpdate = (e) => {
    if (id !== null) {
      if (clientPhone !== phone || clientAreaCode !== areaCode ||(clientName !== client.name && clientName !== "")) {
        handleSaveData();
      }
    }
  }; */

  const handleDataUpdate = () => {
    if(id === null) return;
    const isClientPhoneDifferentAndNotEmpty = isFieldDifferentAndNotEmpty(
      clientPhone,
      phone
    );

    const isClientAreaCodeDifferentAndNotEmpty = isFieldDifferentAndNotEmpty(
      clientAreaCode,
      areaCode
    );

    const isClientNameDifferentAndNotEmpty = isFieldDifferentAndNotEmpty(
      clientName,
      client.name
    );

    if (
      isClientPhoneDifferentAndNotEmpty ||
      isClientAreaCodeDifferentAndNotEmpty ||
      isClientNameDifferentAndNotEmpty
    ) {
      handleSaveData();
    }
  }

  const isFieldDifferentAndNotEmpty = (formField, storedClientData) => {
    return formField !== storedClientData && formField.trim() !== '';
  };

  //search the user in db and set the result in global clientState
  const userEmailConsult = (e) => {
    if (!inputEmailError && e.target.value.trim()) {
      setClientEmail(e.target.value);
      async function fetchData() {
        let fetchClientEmail = await clientsService.getOne(e.target.value);
        let clientFound = await fetchClientEmail["hydra:member"];
        return clientFound;
      }
      fetchData().then((res) => {
        clientDispatch({ type: TYPES.SEARCH_CLIENT, payload: res });
      });
    }
  };

  const handleSaveData = () => {
    async function fetchUpdateDataClient() {
      let clientData = {
        name: clientName,
        phone: clientPhone,
        phone: clientPhone,
        areaCode: clientAreaCode,
      };
      return await clientsService.updateClient(client.id, clientData);
    }
    fetchUpdateDataClient().then((res) => {
      clientDispatch({ type: TYPES.UPDATE_CLIENT, payload: res.data });
    });
  };

  function isEmailValidate(e) {
    let regExEmail =
      /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    if (!regExEmail.test(e.target.value)) {
      setInputEmailError(true);
    } else {
      setInputEmailError(false);
    }
  }

  function areaCodeAndPhoneLengthIsValid(areaCode, phone) {
    let completePhoneNumber = String(areaCode) + String(phone);
    return completePhoneNumber.length > 9 && completePhoneNumber.length == 10
  }

  useEffect(() => {
    if ((String(clientAreaCode) + String(clientPhone)).length < 9) {
      return;
    }
    if(!areaCodeAndPhoneLengthIsValid(clientAreaCode, clientPhone)) {
      setCompletePhoneError(true);
    }else{
      setCompletePhoneError(false);
    }
  }, [clientAreaCode, clientPhone])

  function isPhoneValidator(e) {
    let regExPhone = /^[0-9]+$/;
    if (
      !regExPhone.test(e.target.value) || 
      e.target.value.length !== 6 && 
      e.target.value.length !== 7 && 
      e.target.value.length !== 8) {
      setInputPhoneError(true);
    } else {
      setInputPhoneError(false);
    }
  }

  function areaCodeValidator(e) {
    let regExPhone = /^[0-9]+$/;
    if (
      !regExPhone.test(e.target.value) || 
      e.target.value.length < 2 || 
      e.target.value.length > 4 ) {
      setInputAreaCodeError(true);
    } else {
      setInputAreaCodeError(false);
    }
  }


  function isNameValidator(e) {
    let regExAlpha = /^[a-zA-Z\sñáéíóúü ]*$/;
    if (
      !regExAlpha.test(e.target.value) ||
      e.target.value.trim().length < 3 ||
      e.target.value === ""
    ) {
      setInputNameError(true);
    } else {
      setInputNameError(false);
    }
  }

  const handleInputEmailChange = (e) => {
    isEmailValidate(e);
    setClientEmail(e.target.value);
  };

  const handleInputNameChange = (e) => {
    isNameValidator(e);
    setClientName(e.target.value);
  };

  const handleInputPhoneChange = (e) => {
    isPhoneValidator(e);
    setClientPhone(e.target.value);
  };

  const handleInputAreaCodeChange = (e) => {
    areaCodeValidator(e);
    setClientAreaCode(e.target.value);
  };

  return (
    <div className="row flex-column">
      <div className="container">
        <div className="row">
          <FormInput
            type="email"
            label="Mail*"
            name="email"
            value={clientEmail}
            onBlur={userEmailConsult}
            onChange={handleInputEmailChange}
            error={inputEmailError}
            errorMsg="Ingresa un email válido"
          />
          <FormInput
            type="text"
            label="Nombre y apellido*"
            name="name"
            value={clientName}
            onBlur={handleDataUpdate}
            onChange={handleInputNameChange}
            error={inputNameError}
            errorMsg="Ingresa un nombre válido"
          />
          
        </div>
        <div className="row">
            <FormInput
            type="text"
            label="Cod. Área* (Ej. 11)"
            name="areaCode"
            value={clientAreaCode}
            onBlur={handleDataUpdate}
            onChange={handleInputAreaCodeChange}
            error={inputAreaCodeError}
            errorMsg="Ingresa un código de área válido"
          />
            <FormInput
            type="text"
            label="Número de celular* (Ej. 36718145)"
            name="phone"
            value={clientPhone}
            onBlur={handleDataUpdate}
            onChange={handleInputPhoneChange}
            error={inputPhoneError}
            errorMsg="Ingresa un número de teléfono válido"
          />
          {completePhoneError && <span className="text-danger mb-2">Código de área o teléfono inválido</span>}
        </div>
      </div>
      
    </div>
  );
};

FormInputContainer.propTypes = {
  clientEmail: PropTypes.string,
  clientName: PropTypes.string,
  clientPhone: PropTypes.string,
  setInputNameError: PropTypes.func,
  setInputEmailError: PropTypes.func,
  setInputPhoneError: PropTypes.func,
  inputEmailError: PropTypes.bool,
  inputNameError: PropTypes.bool,
  inputPhoneError: PropTypes.bool,
  setClientEmail: PropTypes.func,
  setClientName: PropTypes.func,
  setClientPhone: PropTypes.func,
  clientDispatch: PropTypes.func,
  clientsService: PropTypes.object,
  TYPES: PropTypes.object,
  id: PropTypes.string,
};

export default FormInputContainer;
