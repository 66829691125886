import React, { useState, useEffect } from "react";
import "./delivery.css";
import zonesServices from "../../../../../services/api_services/zonesServices";

/* Components */

import DeliveryFormDataContainer from "./components/DeliveryFormDataContainer";
import { usePendientOrderContext } from "../../../../../context/pendientOrderContextProvider";
import { useProductsContext } from "../../../../../context/productsContextProvider";
import { MODALITY_CONSTANTS, PATH_STEPS } from "../../../../../constants";
import { useHistory } from "react-router-dom";

function Delivery({
  setClient,
  clientState,
  clientDispatch,
  TYPES,
  setIsComplete,
  showFormErrors,
  setShowFormErrors,
  order,
  setShowFormErrorsMessage,
  modality,
}) {
  const [zones, setZones] = useState([]);
  const [clientZone, setClientZone] = useState("");
  const [zoneHours, setZoneHours] = useState("");

  const isDelivery = clientState.modalityAddressSrc == "delivery";
  const [clientPostalCode, setClientPostalCode] = useState(isDelivery ? clientState.postal_code : null);
  const [clientCity, setclientCity] = useState(isDelivery ? clientState.city : null);
  const [clientAddress, setclientAddress] = useState(isDelivery ? clientState.street : null);
  const [clientStreetNumber, setClientStreetNumber] = useState(isDelivery ? clientState.streetNumber : null);
  const [clientFloor, setclientFloor] = useState(isDelivery ? clientState.floor : null);

  const [deliveryDay, setDeliveryDay] = useState("");
  const [clientAdditionalData, setclientAdditionalData] = useState("");
  const [newClientDelivery, setNewClientDelivery] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [answerPostalCode, setAnswerPostalCode] = useState(false);
  const [inputPostalCodeError, setInputPostalCodeError] = useState(false);
  const { pendientOrderId, updatePendientOrder, isCancel } =
    usePendientOrderContext();
  const { productsByCategory } = useProductsContext();

  const history = useHistory();
  const navigateToPath = (path) => {
    history.push(path);
  };

  //Verifica que los campos requeridos estén completos
  useEffect(() => {
    if (
      clientPostalCode &&
      clientPostalCode !== "" &&
      clientCity &&
      clientCity !== "" &&
      clientAddress &&
      clientAddress !== "" &&
      clientStreetNumber &&
      clientStreetNumber !== "" &&
      deliveryDay !== "" && 
      !postalCodeError
    ) {
      setIsComplete(true);
      setShowFormErrors(false);
      setShowFormErrorsMessage(false);
      if (pendientOrderId && !isCancel) {
        updatePendientOrder(pendientOrderId, order, order.periodId);
      }
    } else {
      setIsComplete(false);
      setShowFormErrorsMessage(true);
    }
  }, [clientAddress, clientCity, clientPostalCode, clientStreetNumber, postalCodeError]);

  useEffect(() => {
    // cambiar la url en el navegador
    if (modality === MODALITY_CONSTANTS.RETIREMENT) {
      navigateToPath(PATH_STEPS.MODALIDAD_PUNTO_CLICK);
    } else if (modality === MODALITY_CONSTANTS.DELIVERY) {
      navigateToPath(PATH_STEPS.MODALIDAD_DELIVERY);
    }
  }, [modality]);

  /* Consulta las zonas en la API y setea el estado */
  useEffect(() => {
    async function fetchZones() {
      return await zonesServices.getAll();
    }
    fetchZones().then((result) => {
      let deliveryZones = result[0].filter((zone) => zone.delivery);
      setZones(deliveryZones);
    });
  }, []);

  /* En cada modificación del estado "DeliveryDay" verifica si están completos todos los campos requeridos */
  useEffect(() => {
    if (
      clientPostalCode &&
      clientPostalCode !== "" &&
      clientCity &&
      clientCity !== "" &&
      clientAddress &&
      clientAddress !== "" &&
      clientStreetNumber &&
      clientStreetNumber !== "" &&
      deliveryDay !== "" &&
      !postalCodeError
    ) {
      setIsComplete(true);
      setShowFormErrorsMessage(false);
    } else {
      setIsComplete(false);
    }
  }, [deliveryDay]);

  /* Verifica si el cliente tiene cargada una entrega anterior */
  useEffect(() => {
    if (clientState.postal_code === "" || clientState === undefined) {
      setNewClientDelivery(true);
    } else {
      if (zones.length) {
        let zone = zones.find((zone) =>
          zone.postalCode.includes(clientPostalCode)
        );
        if (zone !== undefined && zone.delivery) {
          setClientZone(zone);
          setPostalCodeError(false);
          setAnswerPostalCode(true);
        } else {
          setPostalCodeError(true);
        }
      }
    }
  }, [zones]);

  /* Verifica si el usuario modificó los datos cargados en la base de datos y actualiza */
  useEffect(() => {
    let clientData = {
      day: deliveryDay,
      street: clientAddress,
      streetNumber: clientStreetNumber,
      city: clientCity,
      pc: clientPostalCode,
      floor: clientFloor,
      additionalData: clientAdditionalData,
      modalityAddressSrc: modality
    };
    clientDispatch({ type: TYPES.UPDATE_CLIENT_ADDRESS, payload: clientData });
  }, [
    clientAddress,
    clientStreetNumber,
    clientCity,
    clientPostalCode,
    clientFloor,
    clientAdditionalData,
    deliveryDay,
    modality
  ]);

  /* Si el estado del cliente cambia, actualiza los datos */
  useEffect(() => {
    setClient(clientState);
  }, [clientState]);

  return (
    <DeliveryFormDataContainer
      setClientPostalCode={setClientPostalCode}
      setDeliveryDay={setDeliveryDay}
      setclientAdditionalData={setclientAdditionalData}
      setclientAddress={setclientAddress}
      setclientFloor={setclientFloor}
      setclientCity={setclientCity}
      clientAddress={clientAddress}
      clientCity={clientCity}
      clientPostalCode={clientPostalCode}
      clientFloor={clientFloor}
      clientZone={clientZone}
      clientAdditionalData={clientAdditionalData}
      clientStreetNumber={clientStreetNumber}
      setClientStreetNumber={setClientStreetNumber}
      zones={zones}
      postalCodeError={postalCodeError}
      setPostalCodeError={setPostalCodeError}
      answerPostalCode={answerPostalCode}
      showFormErrors={showFormErrors}
      setAnswerPostalCode={setAnswerPostalCode}
      setClientZone={setClientZone}
      deliveryDay={deliveryDay}
    />
  );
}

export default Delivery;
