import { Box, Tab, Tabs } from "@material-ui/core";
import React from "react";


export default function OrderTabs({value, setValue, items}) {

  const handleChange = (event, newValue) => {   
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }} className="tabsContainer">
      <Tabs value={value} onChange={handleChange} centered>
        {items.map(({key, value, tab}, index) => (
          <Tab sx={{width: 80}} key={`${key}${index}`} label={`${value}`} value={tab}/>
        ))}
      </Tabs>
    </Box>
  );
}
