import { TextField } from '@material-ui/core'
import React from 'react'

function FormInput({
    type, 
    name, 
    placeholder, 
    value, 
    disabled, 
    eventHandler,
    error = false,
    errorMsg = "Nada"

}) {
    return (
        <div className="col-md-6 input-group-mui">
            <TextField 
            disabled={disabled}
            type={type}
            id="standard-name" 
            label={placeholder} 
            name={name}
            variant="standard" 
            value={value}
            InputLabelProps={value && { shrink: true }}
            onChange={(e) => eventHandler(e)}
            />
            {error && <span className="text-danger">{errorMsg}</span>}
      </div>
    )
}

export default FormInput
