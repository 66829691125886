import React, { useState, useEffect, useReducer } from "react";
import "./userData.css";

/* Components */
import FormInputContainer from "./components/FormInputContainer";
import clientsServices from "../../../../services/api_services/clientsService";
import ModalityContainer from "./components/ModalityContainer";
import Subtitle from "../../components/Subtitle";
import { useProductsContext } from "../../../../context/productsContextProvider";
import { usePendientOrderContext } from "../../../../context/pendientOrderContextProvider";
import { useClientContext } from "../../../../context/clientProvider";
import { useHistory } from "react-router-dom";
import { MODALITY_CONSTANTS, PATH_STEPS } from "../../../../constants";

function UserData({
  setIsComplete,
  isComplete,
  client,
  setClient,
  modality,
  setModality,
  TYPES,
  clientState,
  clientDispatch,
  pendientOrderClientId,
  setShowFormErrorsMessage,
}) {
  const [clientEmail, setClientEmail] = useState(
    client.email ? client.email : ""
  );
  const [clientName, setClientName] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [clientAreaCode, setClientAreaCode] = useState("");
  const { id, name, phone, areaCode } = clientState;
  const [isNewClient, setIsNewClient] = useState(false);
  const { setIsNew } = useClientContext();
  const [inputEmailError, setInputEmailError] = useState(false);
  const [inputPhoneError, setInputPhoneError] = useState(false);
  const [inputNameError, setInputNameError] = useState(false);
  const [inputAreaCodeError, setInputAreaCodeError] = useState(false);
  const [completePhoneError, setCompletePhoneError] = useState(false);
  const { getPendientOrder, continueWithPendientOrder, pendientOrderId } =
    usePendientOrderContext();

  const history = useHistory();
  const navigateToPath = (path) => {
    history.push(path);
  };
  useEffect(() => {
    if (
      clientName !== undefined &&
      clientName !== "" &&
      clientName !== null &&
      clientEmail !== undefined &&
      clientEmail !== "" &&
      clientEmail !== null &&
      clientPhone !== undefined &&
      clientPhone !== "" &&
      clientPhone !== null &&
      clientAreaCode !== undefined &&
      clientAreaCode !== "" &&
      clientAreaCode !== null &&
      !inputEmailError &&
      !inputNameError &&
      !inputPhoneError &&
      !inputAreaCodeError &&
      !completePhoneError &&
      modality !== ""
    ) {
      setIsComplete(true);
      setShowFormErrorsMessage(false);
    } else {
      setIsComplete(false);
      setShowFormErrorsMessage(true);
    }
  }, [
    ,
    clientName,
    clientEmail,
    clientPhone,
    inputEmailError,
    inputNameError,
    inputPhoneError,
    inputAreaCodeError,
    completePhoneError,
    modality,
  ]);

  useEffect(() => {
    if (clientName || clientEmail || clientPhone || clientAreaCode) {
      if (modality === MODALITY_CONSTANTS.RETIREMENT) {
        navigateToPath(PATH_STEPS.DATOS_PUNTO_CLICK);
      } else if (modality === MODALITY_CONSTANTS.DELIVERY) {
        navigateToPath(PATH_STEPS.DATOS_DELIVERY);
      }
    }
  }, [clientName, clientEmail, clientPhone, clientAreaCode, modality]);

  //Set the states with the values of the old user
  useEffect(() => {
    if (id === null) {
      setIsNewClient(true);
    } else {
      setIsNewClient(false);
      setClientName(name);
      setClientPhone(phone);
      setClientAreaCode(areaCode);
      setClient(clientState);
    }
    setClientName(name);
    setClientPhone(phone);
    setClientAreaCode(areaCode);
    setClient(clientState);
  }, [clientState]);

  useEffect(() => {
    if (client.id) {
      async function fetchData() {
        let fetchClientEmail = await clientsServices.getOne(client.email);
        let clientFound = fetchClientEmail["hydra:member"];
        return clientFound;
      }
      fetchData().then((res) => {
        clientDispatch({ type: TYPES.SEARCH_CLIENT, payload: res });
      });
      setIsComplete(true);
    }
    if (clientState.id !== null) {
      setIsNewClient(false);
    }
  }, []);

  useEffect(() => {
    if (id !== null || typeof id !== "undefined") getPendientOrder(id);
  }, [id]);

  useEffect(() => {
    if (pendientOrderClientId) {
      getPendientOrder(pendientOrderClientId);
      if (pendientOrderId) {
        continueWithPendientOrder();
      }
    }
  }, [pendientOrderClientId, pendientOrderId]);

  const saveUserData = () => {
    let data = {
      name: clientName,
      email: clientEmail,
      phone: clientPhone,
      areaCode: clientAreaCode,
    };

    const newClient = async () => {
      return await clientsServices.newClient(data);
    };
    newClient().then((res) => {
      clientDispatch({ type: TYPES.CREATE_CLIENT, payload: res.data });
      setIsNewClient(false); //change the clientState so that it does not fail in the re-render
    });
  };

  //Create the new client if is a new client, the form is complete and all inputs errors is false
  useEffect(() => {
    if (
      isNewClient &&
      isComplete &&
      !inputEmailError &&
      !inputNameError &&
      !inputAreaCodeError &&
      !inputPhoneError &&
      !completePhoneError
    ) {
      saveUserData();
      setIsNew(true);
    }
  }, [
    isNewClient,
    isComplete,
    inputEmailError,
    inputNameError,
    inputPhoneError,
    inputAreaCodeError,
    completePhoneError,
  ]);

  return (
    <div className="container userData">
      <Subtitle textContent="Datos de contacto" className="form-subtitle" />
      <FormInputContainer
        clientEmail={clientEmail}
        clientName={clientName}
        clientPhone={clientPhone}
        clientAreaCode={clientAreaCode}
        inputEmailError={inputEmailError}
        inputNameError={inputNameError}
        inputPhoneError={inputPhoneError}
        inputAreaCodeError={inputAreaCodeError}
        completePhoneError={completePhoneError}
        setInputNameError={setInputNameError}
        setInputEmailError={setInputEmailError}
        setInputPhoneError={setInputPhoneError}
        setInputAreaCodeError={setInputAreaCodeError}
        setCompletePhoneError={setCompletePhoneError}
        setClientEmail={setClientEmail}
        setClientName={setClientName}
        setClientPhone={setClientPhone}
        setClientAreaCode={setClientAreaCode}
        clientDispatch={clientDispatch}
        clientsService={clientsServices}
        TYPES={TYPES}
        id={id}
        phone={phone}
        client={client}
        areaCode={areaCode}
      />
      <Subtitle
        textContent="Cómo lo retiro o recibo"
        className="form-subtitle"
      />
      <ModalityContainer modality={modality} setModality={setModality} />
    </div>
  );
}

export default UserData;
