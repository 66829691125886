import React, { useState, useEffect, useRef, useCallback } from "react";
import "./order.css";

import {
  incrementProductQuantity,
  decrementProductQuantity,
  comparar,
  calculateTotalWithDiscount,
} from "../../../../utils";
/* Components */
import Discount from "./components/Discount";
import Subtitle from "../../components/Subtitle";
import { useConfigsContext } from "../../../../context/configsContextProvider";
import { usePendientOrderContext } from "../../../../context/pendientOrderContextProvider";
import { useProductsContext } from "../../../../context/productsContextProvider";
import { HowMeetUsSelect } from "./components/HowMeetUsSelect";
import { useClientContext } from "../../../../context/clientProvider";
import OrderWrapper from "./NewCart";
import { useHistory } from "react-router-dom";
import { MODALITY_CONSTANTS, PATH_STEPS } from "../../../../constants";

function Order({
  setProductsOrder,
  order,
  setProductsCart,
  CART_TYPES,
  cartState,
  cartDispatch,
  ORDER_TYPES,
  dispatch,
  clientState,
  discount,
  setDiscount,
  setIsComplete,
  modality,
  refsTotalObjetiveSticky,
}) {
  const { configs } = useConfigsContext();
  const { isNew } = useClientContext();
  const {
    updateProducts,
    productsByCategory,
    productsBag,
    getProductsByActiveBag,
    getAllProducts,
    existFetchProducts,
    lastModality,
  } = useProductsContext();
  const [bag, setBag] = useState(productsByCategory["bag"]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const { cart } = cartState;
  const [totalDeProductos, setTotalDeProductos] = useState(0);
  const { updatePendientOrder, pendientOrderId, isCancel } =
    usePendientOrderContext();
  const [cartChange, setCartChange] = useState(false);
  const history = useHistory();
  const navigateToPath = (path) => {
    history.push(path);
  };

  const handleScroll = () => {
    const item = refsTotalObjetiveSticky.itemTotal?.current;
    const form = refsTotalObjetiveSticky.form?.current;
    const containerTotal = refsTotalObjetiveSticky.containerTotal?.current;
    const formButtons = refsTotalObjetiveSticky.formButtons?.current;

    const locFormButtons = formButtons?.getBoundingClientRect();
    const locForm = form?.getBoundingClientRect();

    if (!item || !form || !containerTotal || !formButtons) return;

      if (locFormButtons.top > window.innerHeight) {
        item.classList.add("fixed-total");
        item.style.right = containerTotal.offsetWidth;
        item.style.width = containerTotal.offsetWidth + "px";
      }
      else if (
      (locFormButtons.top <= window.innerHeight && locFormButtons.bottom >= 0) ||
      (locForm.top <= window.innerHeight && locForm.bottom >= 0)
    ) {
      item.classList.remove("fixed-total");
    }

  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  /* Define si el paso esta completo */
  useEffect(() => {
    let bolsonesEnLaCompra = cart.find((product) => product.category == "bag");
    if (typeof bolsonesEnLaCompra === "undefined") {
      const hasFullDiscount = discount.type === "%" && discount.value === 100;
      const totalWithReturnables = cart.reduce((acc, product) => {
        if (product.category.name !== "retornables") {
          acc += product.price * product.orderQuantity;
        }
        return acc;
      }, 0);

      if (
        /* totalDeProductos */ totalWithReturnables < configs["MIN_AMOUNT"] &&
        !hasFullDiscount
      ) {
        setIsComplete(false);
      } else {
        setIsComplete(true);
      }
    } else {
      setIsComplete(true);
    }
  }, [total, setTotal]);

  /* Agrega el bolson en la orden */
  /* Setea estados */
  useEffect(() => {
    if (!existFetchProducts) {
      getAllProducts();
    }
    setIsComplete(false);
    setLoading(false);
  }, []);

  useEffect(() => {
    // cambiar la url en el navegador
    if (modality === MODALITY_CONSTANTS.RETIREMENT) {
      navigateToPath(PATH_STEPS.PEDIDO_PUNTO_CLICK);
    } else if (modality === MODALITY_CONSTANTS.DELIVERY) {
      navigateToPath(PATH_STEPS.PEDIDO_DELIVERY);
    }
  }, [modality]);

  useEffect(() => {
    if (modality !== lastModality) {
      getAllProducts();
    }
  }, [modality]);

  /* Actualiza el listado de productos de el contexto en cada cambio del carrito */
  useEffect(() => {
    updateProducts();

    let productsToOrder = cart.map((product) => {
      return {
        product: `/api/products/${product.id}`,
        quantity: product.orderQuantity,
        returnableQuantity: product.returnableQuantity,
        isReturnable: product.isReturnable,
      };
    });
    setProductsOrder(productsToOrder);
    setProductsCart(cart);
  }, [cart]);

  /* Actualiza el estado de la orden pendiente en el contexto solo si la misma no fue cancelada y si el carrito cambia y si existe la orden pendiente */
  useEffect(() => {
    if (pendientOrderId && !isCancel && cartChange) {
      updatePendientOrder(pendientOrderId, order, order.periodId);
    }
  }, [order]);

  /* Cálculo del total del pedido. */
  useEffect(() => {
    let prices = cart.map((product) => product.price * product.orderQuantity);
    let total =
      prices.length > 0 ? prices.reduce((acum, num) => +acum + +num) : 0;
    setTotalDeProductos(total);
    let finalResult = calculateTotalWithDiscount(
      discount.type,
      discount.value,
      discount.maxDiscount,
      total
    );
    setTotal(finalResult);
  }, [cart, order, discount]);

  const addToCart = (product, isBag = false, number, order) => {
    setCartChange(true);
    if (isBag) {
      let orderBag = {
        ...bag,
        orderQuantity: number,
      };
      cartDispatch({ type: CART_TYPES.ADD_TO_CART, payload: orderBag });
    } else {
      if (total === 0 && product.category.name === "retornables") {
        return;
      }
      incrementProductQuantity(product);
      cartDispatch({ type: CART_TYPES.ADD_TO_CART, payload: product });
    }
  };

  const delFromCart = (product, all = false) => {
    setCartChange(true);
    if (all) {
      cartDispatch({ type: CART_TYPES.REMOVE_ALL_FROM_CART, payload: product });
    } else {
      decrementProductQuantity(product);
      cartDispatch({ type: CART_TYPES.REMOVE_ONE_FROM_CART, payload: product });
    }
  };

  const incrementReturnableQuantity = (product) => {
    setCartChange(true);

    product.returnableQuantity++;
    cartDispatch({ type: CART_TYPES.ADD_TO_CART, payload: product });
  };

  const decrementReturnableQuantity = (product) => {
    setCartChange(false);
    product.returnableQuantity--;

    cartDispatch({ type: CART_TYPES.ADD_TO_CART, payload: product });
  };

  return (
    <div className="order-section" ref={refsTotalObjetiveSticky.containerTotal}>
      <OrderWrapper
        addToCart={addToCart}
        delFromCart={delFromCart}
        order={order}
        dispatch={dispatch}
        incrementReturnableQuantity={incrementReturnableQuantity}
        decrementReturnableQuantity={decrementReturnableQuantity}
      />

      <Discount
        clientState={clientState}
        discount={discount}
        setDiscount={setDiscount}
        total={total}
        totalDeProductos={totalDeProductos}
      />
      {isNew && <HowMeetUsSelect dispatch={dispatch} />}

      <div
        className="total_box-container"
        ref={refsTotalObjetiveSticky.itemTotal}
      >
        <div className="total_box">
          <Subtitle
            textContent={`Total: $${total}`}
            className="form-subtitle_order bag-total"
          />
        </div>
      </div>
    </div>
  );
}

export default Order;
