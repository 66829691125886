import React, { useEffect, useState } from "react";
import ItemPromotion from "./ItemPromotion";
import {
  MODALITY_CONSTANTS,
  PAYMENT_METHODS_KEYS,
  PAYMENT_METHODS_TEXT,
} from "../../../../../constants";
import promotionServices from "../../../../../services/api_services/promotionsService";
// import { AccordionPromotion } from "./AccordionPromotion";
// import { orderElements } from "../../../../../utils";

export default function CurrentPromotions({ modalityState }) {
  const origin = window.location.origin;

  const paymentMethods = {
    [PAYMENT_METHODS_KEYS.MP]: {
      name: PAYMENT_METHODS_TEXT.MP,
      icons: [origin + "/build/images/iconoMp.74b13b66.png"],
      items: [],
    },
    [PAYMENT_METHODS_KEYS.MODO]: {
      name: PAYMENT_METHODS_TEXT.MODO,
      icons: [origin + "/build/images/iconoModo.e9f161a1.png"],
      items: [],
    },
    [PAYMENT_METHODS_KEYS.NAVE]: {
      name: PAYMENT_METHODS_TEXT.NAVE,
      icons: [origin + "/build/images/iconoNave.9b4bb901.png"],
      items: [],
    },
    [PAYMENT_METHODS_KEYS.CTA_DNI]: {
      name: PAYMENT_METHODS_TEXT.CTA_DNI,
      icons: [origin + "/build/images/iconoCuentadni.a5fb4434.png"],
      items: [],
    },
    [PAYMENT_METHODS_KEYS.BNA]: {
      name: PAYMENT_METHODS_TEXT.BNA,
      icons: [origin + "/build/images/iconoBNA.c449c86b.png"],
      items: [],
    },
  };

  const [promotions, setPromotions] = useState([]);
  const [titlePromotion, setTitlePromotion] = useState("");
  // const [typingData, setTypingData] = useState("object"); // object o array
  const [showPromotion, setShowPromotion] = useState(false);

  useEffect(() => {
    promotionServices.getCurrentPromotions().then(
      ({
        data: {
          status,
          data: { title, paymentMethodsAndPromotions },
        },
      }) => {


        if (status === "true") {
          let modalityLocal = "Delivery";
          let promotionsData;
          let promotionsDataMapped;

          if (modalityState === MODALITY_CONSTANTS.RETIREMENT) {
            modalityLocal = "Pickup";
          }

          /*
            if (modalityState === MODALITY_CONSTANTS.RETIREMENT) {
            modalityLocal = "Pickup";

            promotionsData = Object.entries(
              paymentMethodsAndPromotions[modalityLocal]
            );

            // data initial
            const paymentMethodsPoints = {};
            promotionsData.forEach(([day]) => {
              paymentMethodsPoints[day] = {};
            });

            promotionsData.forEach(([day, promotions]) => {
              promotions.forEach(({ name: typesPayment, items }) => {
                typesPayment.forEach((name) => {
                  if (!paymentMethodsPoints[day][name]) {
                    paymentMethodsPoints[day][name] = {
                      name,
                      icons: paymentMethods[name].icons,
                      items: [],
                    };
                  }
                  paymentMethodsPoints[day][name].items =
                    paymentMethodsPoints[day][name].items.concat(items);
                });
              });
            });


            title = "PROMOCIONES PUNTOS CLICK";

            promotionsDataMapped = Object.entries(paymentMethodsPoints).reduce(
              (dataFormat, [day, data]) => {
                const payments = Object.entries(data).map(([_, data]) => data);
                dataFormat[day] = payments;
                return dataFormat;
              },
              {}
            );
            const existPromotions = Object.entries(promotionsDataMapped).some(
              ([key, promotion]) => promotion.length
            );

            setShowPromotion(existPromotions);
            const daysOrder = [
              "TODOS LOS DÍAS",
              "LUNES",
              "MARTES",
              "MIERCOLES",
              "JUEVES",
              "VIERNES",
              "SABADO",
              "DOMINGO",
            ];
            promotionsDataMapped = orderElements(
              promotionsDataMapped,
              daysOrder
            );


          } else {

            promotionsData = paymentMethodsAndPromotions[modalityLocal].reduce(
              (promotionFormat, { name: typesPayment, items }) => {
                // name -> ["MP", "MODO"]
                typesPayment.forEach((name) => {
                  if (!promotionFormat[name]?.items)
                    promotionFormat[name] = { items };
                  else promotionFormat[name].items.push(...items);
                });
                return promotionFormat;
              },
              paymentMethods
            );

            promotionsDataMapped = Object.entries(promotionsData).map(
              ([key, promotion]) => promotion
            );

            const existPromotions = promotionsDataMapped.some(
              (promotion) => promotion.items.length
            );
            setShowPromotion(existPromotions);
          } */


          promotionsData = paymentMethodsAndPromotions[modalityLocal].reduce(
            (promotionFormat, { name: typesPayment, items }) => {
              // name -> ["MP", "MODO"]
              typesPayment.forEach((name) => {
                if (!promotionFormat[name]?.items)
                  promotionFormat[name] = { items };
                else promotionFormat[name].items.push(...items);
              });
              return promotionFormat;
            },
            paymentMethods
          );

          promotionsDataMapped = Object.entries(promotionsData).map(
            ([key, promotion]) => promotion
          );

          const existPromotions = promotionsDataMapped.some(
            (promotion) => promotion.items.length
          );
          setShowPromotion(existPromotions);

          setTitlePromotion(title);
          setPromotions(promotionsDataMapped);          
        }

        
      }
    );
  }, [modalityState]);

  /* useEffect(() => {
    if (typeof promotions === "object" && !Array.isArray(promotions)) {
      setTypingData("object");
    } else if (Array.isArray(promotions)) {
      setTypingData("array");
    }
  }, [promotions]); */

  return showPromotion ? (
    <div
      className={'form promotions-current-date container-day col-12 active-border'}
        /* ${ modalityState === MODALITY_CONSTANTS.DELIVERY ? "active-border" : "p-0"} */
    >
      <h5 className="day-title mb-2 mb-lg-4">{titlePromotion}</h5>
      <div className="row">
        {/* {modalityState === MODALITY_CONSTANTS.RETIREMENT
          ? typingData === "object" && (
              <AccordionPromotion data={Object.entries(promotions)} />
            )
          : typingData === "array" &&
            promotions
              ?.filter(({ items }) => items?.length)
              .map((promotion, i) => <ItemPromotion key={i} {...promotion} />)} */}
          {
            promotions
            ?.filter(({ items }) => items?.length)
            .map((promotion, i) => <ItemPromotion key={i} {...promotion} />)
          }
      </div>
    </div>
  ) : null;
}
