import { CART_TYPES } from "../actions/productsCartActions";

export const productsCartIntialState = {
  cart: [],
};

export function productsCartReducer(state, action) {
  switch (action.type) {
    case CART_TYPES.ADD_TO_CART: {
      let newItem = action.payload;
      let itemInCart = state.cart.find((item) => item.id === newItem.id);
      return itemInCart
        ? {
            ...state,
            cart: state.cart.map((item) =>
              item.id === newItem.id
                ? {
                    ...item,
                    orderQuantity: newItem.orderQuantity,
                    returnableQuantity: newItem.returnableQuantity,
                  }
                : item
            ),
          }
        : {
            ...state,
            cart: [
              ...state.cart,
              {
                ...newItem,
                orderQuantity:
                  newItem.category === "bag" ? newItem.orderQuantity : 1,
                returnableQuantity: 0,
              },
            ],
          };
    }
    case CART_TYPES.ADD_TO_CART_PENDIENT_ORDER: {
      let newItem = action.payload;
      return {
        ...state,
        cart: [
          ...state.cart,
          {
            ...newItem,
            orderQuantity: newItem.orderQuantity,
          },
        ],
      };
    }
    case CART_TYPES.REMOVE_ONE_FROM_CART: {
      let itemToDelete = state.cart.find(
        (item) => item.id === action.payload.id
      );
      return itemToDelete.orderQuantity > 1
        ? {
            ...state,
            cart: state.cart.map((item) =>
              item.id === action.payload.id && action.payload.id !== 0
                ? {
                    ...item,
                    orderQuantity: item.orderQuantity - 1,
                    returnableQuantity:
                      item.returnableQuantity == item.orderQuantity
                        ? item.orderQuantity - 1
                        : item.returnableQuantity,
                  }
                : item
            ),
          }
        : {
            ...state,
            cart: state.cart.filter((item) => item.id !== action.payload.id),
          };
    }
    case CART_TYPES.REMOVE_ALL_FROM_CART: {
      return {
        ...state,
        cart: state.cart.filter((item) => item.id !== action.payload.id),
      };
    }
    case CART_TYPES.CLEAR_CART:
      return productsCartIntialState;
    default:
      return state;
  }
}
