import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/* Components */
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { useConfigsContext } from "../../../../../../context/configsContextProvider";

const DeliverySelectDaysContainer = ({
  answerPostalCode,
  showFormErrors,
  deliveryDay,
  setDeliveryDay,
  clientZone,
}) => {
  const { configs } = useConfigsContext();
  const [activeDeliveryDays, setActiveDeliveryDays] = useState();

  useEffect( () => {
    const deliveryDays = Object.entries(configs)
    .filter(([key, _]) => /DELIVERY_DAY_[A-Z]/.test(key))
    .filter(([_, value]) => value?.active)
    .map(([_,value]) => value)

    setActiveDeliveryDays(deliveryDays)
  }, [])

  const handleDeliveryDay = (e) => setDeliveryDay(e.target.value);

  return (
    <div className="col-md-6">
      <FormControl
          variant="standard"
          className="form-control_delivery-select"
        >
          <InputLabel id="demo-simple-select-standard-label">
            Elegí tu día de entrega*
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={deliveryDay}
            onChange={(e) => handleDeliveryDay(e)}
            label="Elegí una zona*"
          >
            <MenuItem value="elegi" hidden>
              <em>Elegí tu día de entrega*</em>
            </MenuItem>
            {activeDeliveryDays?.map((day, index) => (
              <MenuItem key={index} value={day.value}>
                {day.value}
              </MenuItem>
            ))}
          </Select>
          {showFormErrors && !deliveryDay && (
            <small className="text-danger">Campo requerido</small>
          )}
        </FormControl>
      {/* {!answerPostalCode ? (
        <FormControl
          variant="standard"
          className="form-control_delivery-select"
        >
          <InputLabel id="demo-simple-select-standard-label">
            Elegí tu día de entrega*
          </InputLabel>
          <Select value={""} label="Elegí tu día de entrega*"></Select>
          {showFormErrors && !deliveryDay && (
            <small className="text-danger">Campo requerido</small>
          )}
        </FormControl>
      ) : (
        
      )} */}
    </div>
  );
};

DeliverySelectDaysContainer.propTypes = {
  answerPostalCode: PropTypes.bool,
  showFormErrors: PropTypes.bool,
  deliveryDay: PropTypes.string,
  setDeliveryDay: PropTypes.func,
};

export default DeliverySelectDaysContainer;
