import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const FilterSelect = ({
    setState,
    array = [],
    label = "",
    menuItemText = "",
    menuItemValue = "",
    isDay = false,
    selectedDay,
    selectedZone
}) => {
    return (
        <div className="col-sm-12 col-md-6">
          <FormControl
            variant="standard"
            sx={{ m: 1, minWidth: 120 }}
            className="form-control_select"
          >
            <InputLabel id="demo-simple-select-standard-label">
              {label}
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              onChange={(e) => setState(e.target.value)}
              label={label}
              value={isDay ? selectedDay : selectedZone }
            >
              <MenuItem value={menuItemValue}>
                <em>{menuItemText}</em>
              </MenuItem>
              {array.map((item, index) => (
                <MenuItem className="item-day" value={isDay ? item : item.id} key={isDay ? index : item.id}>
                  {isDay ? item : item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
    );
}

export default FilterSelect;
