import React from "react";
import PropTypes from "prop-types";

import { Radio, FormControlLabel } from "@material-ui/core";

const PointItem = ({ point, handlePoint, pointInOrder }) => {
  const pointClick = () => {
    if (point.active) {
      handlePoint(point.id, point.openDays);
    }
  };

  return (
    <div>
      <FormControlLabel
        checked={pointInOrder === point.id}
        value={point.id}
        disabled={!point.active}
        control={<Radio disabled={!point.active} />}
        onClick={pointClick}
      />
      <span className={!point.active ? "point__name-disabled" : "point__name"}>{point.name}</span>
      {point.new && <span className="point__new"> Nuevo!</span>}
      <span className="address-span">{`> ${point.address}`}</span>
      <span className="modality-info">
        {point.openDays} {point.openHours}
        {
        !point.active && <span className="point-observation">{` - ${point.observation || "Momentáneamente Inactivo"}`}</span>
      }
      </span>
      
    </div>
  );
};

PointItem.propTypes = {
  point: PropTypes.object,
  handlePoint: PropTypes.func,
};

export default PointItem;
