import React, { useEffect, useState } from "react";
import "./point.css";

import pointsServices from "../../../../../services/api_services/pointsService";

/* Components */
import FiltersBox from "./components/filtersBox";
import Subtitle from "../../../components/Subtitle";
import PointsContainer from "./components/PointsContainer";
import { orderByName, scrollTo } from "../../../../../utils";
import { useProductsContext } from "../../../../../context/productsContextProvider";
import { useHistory } from "react-router-dom";
import { MODALITY_CONSTANTS, PATH_STEPS } from "../../../../../constants";

function Point({
  setIsComplete,
  setSelectedPoint,
  order,
  setShowFormErrorsMessage,
  modality,
  clientDispatch,
  TYPES
}) {
  const [zones, setZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [points, setPoints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredPoints, setFilteredPoints] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [pointInOrder, setPointInOrder] = useState(null);
  const history = useHistory();
  const navigateToPath = (path) => {
    history.push(path);
  };

  useEffect(() => {
    // cambiar la url en el navegador
    if (modality === MODALITY_CONSTANTS.RETIREMENT) {
      navigateToPath(PATH_STEPS.MODALIDAD_PUNTO_CLICK);
    } else if (modality === MODALITY_CONSTANTS.DELIVERY) {
      navigateToPath(PATH_STEPS.MODALIDAD_DELIVERY);
    }
  }, [modality]);

  useEffect(() => {
    setShowFormErrorsMessage(true);
  }, []);

  function handlePoint(id, openDays) {
    setSelectedPoint({ id, openDay: openDays });
    setIsComplete(true);
    scrollTo("next", "center");
    
    const p = points.find((point) => point.id === id);
    const streetNumber = p.address?.match(/\d{3,}/g) || ["0000"]

    let clientData = {
      day: `${p.day} ${p.openHours}`,
      street: p.address,
      streetNumber: streetNumber[0],
      city: p.name,
      pc: "-",
      floor: "0",
      additionalData: "-",
      modalityAddressSrc: modality
    };
    
    clientDispatch({ type: TYPES.UPDATE_CLIENT_ADDRESS, payload: clientData });
  }

  useEffect(() => {
    const pointInUri = order.point?.slice(12);
    setPointInOrder(pointInUri);
  }, [order]);

  /* useEffect(() => {
    if (pointInOrder && selectedZone && selectedDay) {
      setIsComplete(true);
      scrollTo("next", "center");
      setShowFormErrorsMessage(false);
    } else {
      setShowFormErrorsMessage(true);
    } 
  }, [pointInOrder, selectedZone, selectedDay]); */

  useEffect(() => {
    const fetchPoints = async () => await pointsServices.getAllPoints();
    const fetchZones = async () => await pointsServices.getAllZones();

    fetchZones().then((res) => {
      let zonesWithPoints = res[0].filter((zone) => zone.points.length > 0);
      setZones(zonesWithPoints);
    });

    fetchPoints().then((res) => {
      let activePoints = res.data;
      setPoints(orderByName(activePoints));
      setLoading(false);
    });
    setIsComplete(false);
  }, []);

  return (
    <div className="points-section">
      <Subtitle textContent="Lugar de retiro" className="form-subtitle" />
      <FiltersBox
        setFilteredPoints={setFilteredPoints}
        setIsFilter={setIsFilter}
        selectedZone={selectedZone}
        setSelectedZone={setSelectedZone}
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        isFilter={isFilter}
        points={points}
        zones={zones}
      />
      <Subtitle textContent="Punto Click" className="form-subtitle" />
      <PointsContainer
        isFilter={isFilter}
        filteredPoints={filteredPoints}
        points={points}
        loading={loading}
        handlePoint={handlePoint}
        pointInOrder={pointInOrder}
      />
    </div>
  );
}

export default Point;
