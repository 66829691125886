import React, { useEffect, useRef, useState } from "react";
import { usePendientOrderContext } from "../../../context/pendientOrderContextProvider";
import { scrollTo } from "../../../utils";
import { useConfigsContext } from "../../../context/configsContextProvider";
import productsServices from "../../../services/api_services/productsService";
import { STEPS } from "../../../constants";

function FormButtons({
  setActiveStep,
  activeStep,
  skipped,
  setSkipped,
  getStepContent,
  cartState,
  isComplete,
  steps,
  isStepSkipped,
  setShowFormErrors,
  order,
  clientState,
  autoConfirmOrder,
  showFormErrorsMessage,
  setShowFormErrorsMessage,
  refsTotalObjetiveSticky
}) {
  const [showMessageButton, setShowMessageButton] = useState(false);
  const {
    createPendientOrder,
    updatePendientOrder,
    pendientOrderId,
    isCancel,
  } = usePendientOrderContext();
  const { configs } = useConfigsContext();
  const [showFeedback, setShowFeedback] = useState(false);
  const [msgFeedback, setMsgFeedback] = useState("");

  const refContainerButton = useRef(null);
  /*  useEffect(() => {
    const fetchProducts = async () => await productsServices.getAll();
    fetchProducts().then((res) => {
      const products = res.data;
      const bag = products.find(
        (prod) => prod.category === "bag" && prod.active
      );
      setBagId(bag.id);
    });
  }, []);
 */
  const handleNext = () => {
    scrollTo("form", "start");
    setTimeout(() => {
      if (activeStep === STEPS.CONFIRMAR) {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      } else {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
    }, 1000);
  };

  const waitToBackTime = activeStep === STEPS.MODALIDAD /* 1 */ ? 1300 : 500;
  const handleBack = () => {
    scrollTo("form", "start");
    setTimeout(() => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }, waitToBackTime);
  };

  const showFormErrors = () => {
    setShowFormErrors(true);
  };

  const showFeedbackMessage = (setterCallbackFunction) => {
    setterCallbackFunction(true);
    setTimeout(() => {
      setterCallbackFunction(false);
    }, 3500);
  };

  /*
  Si el paso es el posterior a puntos o delivery, se crea la orden pendiente,
  en el caso de que se haya llegado al paso por haber presionado el boton atrás 
  desde un paso posterior no se debe crear la orden nuevamente 
  */

  useEffect(() => {
    if (activeStep === STEPS.PEDIDO /* 2 */ && !pendientOrderId) {
      if (pendientOrderId) {
        updatePendientOrder(clientState.id, order, order.periodId);
      } else {
        if (!isCancel) {
          createPendientOrder(
            clientState.id,
            order,
            order.periodId,
            order?.address?.day
          );
        }
      }
    }
  }, [activeStep]);


  const buttonText = (activeStep, steps, order) => {
    switch (true) {
      case activeStep === steps.length - 1 &&
        order.type === "DELIVERY" &&
        !autoConfirmOrder:
        return "PAGAR ONLINE";
      case activeStep === steps.length - 1 &&
        order.type === "DELIVERY" &&
        autoConfirmOrder:
        return "PAGAR ONLINE";
      case activeStep === steps.length - 1 && order.type === "PICKUP":
        return "PAGAR ONLINE";
      default:
        return "CONTINUAR";
    }
  };

  const showFeedbackToUser = (activeStep) => {
    switch (true) {
      case activeStep === STEPS.DATOS && showFormErrorsMessage:
        showFeedbackMessage(setShowFeedback);
        setMsgFeedback("Completar todos los campos para poder continuar");
        break;
      case activeStep === STEPS.MODALIDAD /* 1 */ && showFormErrorsMessage:
        showFeedbackMessage(setShowFeedback);
        setMsgFeedback("Completar todos los campos para poder continuar");
        showFormErrors();
        break;
      case activeStep === STEPS.MODALIDAD /* 1 */:
        showFormErrors();
        setMsgFeedback("Completar todos los campos para poder continuar");
        break;
      case activeStep === STEPS.PEDIDO /* 2 */:
        showFeedbackMessage(setShowMessageButton);
        setMsgFeedback(`Tenés que llegar al mínimo de compra de ${configs.MIN_AMOUNT} para
            continuar`);
        break;
      case activeStep === STEPS.CONFIRMAR /* 3 */:
        showFeedbackMessage(setShowMessageButton);
        setMsgFeedback("Tenés que seleccionar una opción de pago");
        break;
      default:
        break;
    }
  };

  return (
    <div>
      {getStepContent(activeStep)}
      <div className="btn-container" ref={refsTotalObjetiveSticky.formButtons}>
        <button
          onClick={handleBack}
          disabled={activeStep === STEPS.DATOS /* 0 */}
          className="back-button"
        >
          VOLVER
        </button>
        {(showMessageButton || showFeedback) && (
          <div className="messageButton">
            {msgFeedback}
          </div>
        )}
        
    
        {!isComplete ? (
          <div
            onClick={() => showFeedbackToUser(activeStep)}
            className="form-button"
            id="next"
          >
            {buttonText(activeStep, steps, order)}
          </div>
        ) : (
           <button
            disabled={!isComplete}
            onClick={handleNext}
            className="form-button"
            id="next"
          >
            {buttonText(activeStep, steps, order)}
          </button> 
        )} 
      </div>
    </div>
  );
}

export default FormButtons;
